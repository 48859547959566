<template>
  <section>
    <div class="w-full mt-4 shadow p-2 rounded-md">
      <div class="w-full">
        <div class="flex gap-2">
          <button @click="show = !show" class="flex gap-2">
            <i v-if="show" class="pi pi-chevron-down text-blue-800 text-xs"></i>
            <i v-else class="pi pi-chevron-right text-blue-800 text-xs"></i>
            <p class="text-blue-800 font-bold">ARTÍCULOS</p>
          </button>
          <button @click="toggle">
            <i class="pi pi-info-circle text-blue-800"></i>
          </button>
          <OverlayPanel ref="op">
            <div class="w-full grid grid-cols-1 gap-1">
              <div class="gap-1 flex items-center">
                <div class="w-4 h-4 bg-red-100 rounded-full"></div>
                <p class="text-xs">Artículo sin contrato precio o apartado sin precio</p>
              </div>
              <div class="gap-1 flex items-center">
                <div class="w-4 h-4 bg-green-100 rounded-full"></div>
                <p class="text-xs">Artículo en stock de disponibles</p>
              </div>
              <div class="gap-1 flex items-center">
                <div class="w-4 h-4 bg-yellow-200 rounded-full"></div>
                <p class="text-xs">Artículo en stock de apartados</p>
              </div>
            </div>
          </OverlayPanel>
        </div>
        <div class="my-2" v-if="show">
          <div class="flex">
            <div class="w-full">
              <AutoComplete
                @item-select="validarIngresoMedicamento(medicamentoSeleccionado)"
                v-model="medicamentoSeleccionado"
                placeholder="Buscar artículo (mínimo 3 caracteres)..."
                id="inputElement"
                inputClass="w-full text-xs"
                v-tooltip.top="'Para activar el buscador (Ctrl + y)'"
                panelClass="text-xs h-panel"
                class="p-inputtext-sm my-4 w-full text-xs"
                :suggestions="medicamentosStore._listado_medicamentos"
                autofocus
                @complete="buscarMedicamentos($event)"
                :forceSelection="true"
                optionLabel="name"
              >
              <template #item="slotProps">
                <div
                  class="flex gap-2 items-center p-1 rounded-md"
                >
                  <img class="w-10" alt="Imagen del artículo" :src="`${baseUrl}/articulos/get-image/${slotProps.item.PicturName}`">
                  <div v-if="slotProps.item.StockTotal">
                    <p :style="{'text-wrap': 'wrap !important'}" class="p-0">{{
                        slotProps.item.CodMx + ' - ' + slotProps.item.ItemName + slotProps.item.UserText
                      }}</p>
                    <div class="flex items-center gap-2">
                      <p class="font-bold">Fec. venc.:</p>
                      <p class="font-medium">{{ dayjs(slotProps.item.FecVcto).format('DD-MM-YYYY') }}</p>
                      <p class="font-bold">Lote:</p>
                      <p class="font-medium">{{ slotProps.item.NumLote }}</p>
                      <p class="font-bold">Precio:</p>
                      {{ slotProps.item.Price }}
                      <p :class="slotProps.item.Price ? 'text-green-600' : ''"
                          class="font-medium">{{
                          slotProps.item.Price ? $h.formatCurrency(slotProps.item.Price) : '...ESTE ARTÍCULO NO CUENTA CON CONTRATO PRECIO'
                        }}
                      </p>
                      <p class="font-bold">Stock total:</p>
                      <p class="font-medium">{{ slotProps.item.StockTotal }}</p>
                      <p class="font-bold">Disp.:</p>
                      <p class="font-medium p-1 rounded-md ">{{ slotProps.item.StockDisponible }}</p>
                      <p class="font-bold">Cant. lote:</p>
                      <p class="font-medium p-1 rounded-md ">{{ slotProps.item.cantstock }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="p-0">{{ slotProps.item.CodMx + ' - ' + slotProps.item.ItemName }}</p>
                    <div class="flex gap-2">
                      <p class="font-bold">Lote:</p>
                      <p class="font-medium">{{ slotProps.item.NumLote ? slotProps.item.NumLote : 'NO TIENE'  }}</p>
                      <p class="font-bold">Cant Stock:</p>
                      <p class="font-medium">0</p>
                    </div>
                  </div>
                </div>
              </template>
              </AutoComplete>
            </div>
            <div class="w-min flex justify-end">
              <button v-if="lineasMdsSeleccionadas.length || lineasLotesSeleccionados.length" @click="eliminarMedicamentos"><i class="pi pi-trash text-red-600 mx-4 text-xs"></i></button>
            </div>
          </div>
          <DataTable
            :value="medicamentosStore._medicamentos_tabla"
            dataKey="ItemCode"
            class="p-datatable-sm"
            editMode="cell"
            v-model:selection="lineasMdsSeleccionadas"
            v-model:expandedRows="expandedRows"
            responsiveLayout="scroll">
            <template #empty>
              <p class="text-xs">
                No se encontraron artículos, ingrese uno con Ctrl + y, para iniciar.
              </p>
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
            <Column :expander="true" headerStyle="width: 3em" >
              <template #header>
                <button v-if="expandedRows && expandedRows.length" @click="expandedRows = null"><i class="pi pi-angle-down"></i></button>
                <button v-else @click="expandedRows = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode)"><i class="pi pi-angle-right"></i></button>
              </template>
            </Column>
            <Column header="Código mipres" v-if="validarColumnasTabla('U_PHR_CodMipres')" class="text-xs" headerClass="text-xs"  :style="{'min-width': '10rem', 'max-width': '13rem'}">
              <template #body="slotProps">
                <Dropdown
                  panelClass="text-xs"
                  optionLabel="noprescripcion"
                  optionValue="noprescripcion"
                  @change="mipresStore.validarNumerosDireccionamiento(slotProps.data.U_PHR_CodMipres)"
                  v-model="slotProps.data.U_PHR_CodMipres"
                  class="w-full border rounded-md"
                  :options="lodash.uniqBy(codigosMipres, 'noprescripcion')"
                  :class="validacionCampoRequerido('U_PHR_CodMipres', slotProps.data.U_PHR_CodMipres) ? 'border-red-600' : 'border-gray-300'"
                />
                <span v-if="validacionCampoRequerido('U_PHR_CodMipres', slotProps.data.U_PHR_CodMipres)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Num. Direccionamiento" v-if="validarColumnasTabla('U_PHR_NumDirec')" class="text-xs" headerClass="text-xs"  :style="{'min-width': '18rem', 'max-width': '20rem'}">
              <template #body="slotProps">
                <Dropdown
                  panelClass="text-xs"
                  optionLabel="name"
                  :disabled="!slotProps.data.U_PHR_CodMipres"
                  optionValue="iddireccionamiento"
                  showClear
                  @change="cambioNumeroDireccionamiento(slotProps.data)"
                  v-model="slotProps.data.U_PHR_NumDirec"
                  class="w-full border border-gray-300 rounded-md"
                  :options="validarOpcionesIdsDireccionamiento(slotProps.data)"
                  :class="validacionCampoRequerido('U_PHR_NumDirec', slotProps.data.U_PHR_NumDirec) ? 'border-red-600' : 'border-gray-300'"
                ></Dropdown>
                <span v-if="validacionCampoRequerido('U_PHR_NumDirec', slotProps.data.U_PHR_NumDirec)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Articulo" class="text-xs" headerClass="text-xs" :style="{'min-width': '26rem'}">
              <template #body="slotProps">
                <div class="w-full flex gap-2">
                  <button v-tooltip.top="'Ver pendiente'" class="w-1/12" @click="abrirModalPendiente(slotProps.data)" v-if="slotProps.data.OpenCreQty"><i class="pi pi-exclamation-circle text-red-600"></i></button>
                  <div class="w-full">
                    <div class="flex gap-2 w-full">
                      <span class="flex gap-2 items-center w-full">
                        <div class="w-10/12">
                          {{ slotProps.data.ItemCode + ' - ' + slotProps.data.ItemName }}
                        </div>
                        <div class="w-2/12 flex justify-end gap-2">
                          <button v-tooltip.top="'Ver artículo'" @click="obtenerDetalleMedicamento(slotProps.data)">
                            <i class="pi pi-eye text-blue-600"></i>
                          </button>
                          <button v-tooltip.top="'Reemplazar artículo'" @click="validarReemplazarMedicamentoAdvertencia(slotProps.data)">
                            <i class="pi pi-replay text-green-600"></i>
                          </button>
                        </div>
                      </span>
                    </div>
                    <div class="flex gap-2">
                      <p class="font-bold">Stock total:</p><p>{{ slotProps.data.stock_total_unitario }}</p>
                      <p class="font-bold">Stock dispon.:</p><p>{{ slotProps.data.stock_disponible_unitario }}</p>
                      <p class="font-bold">Precio:</p><p class="font-bold text-green-600">{{ $h.formatCurrency(parseInt(slotProps.data.UnitPrice)) }}</p>
                      <p>
                        {{ slotProps.data.unidadSeleccionada.description }}
                        <i
                          v-tooltip.top="'Cambiar Unidad'"
                          v-if="slotProps.data.unidades_medida.length > 1"
                          @click="modalEditUnidades = true, unidadEditar = slotProps.data.ItemCode"
                          class="pi pi-replay text-red-600 cursor-pointer ml-3 text-sm">
                        </i>
                        <Dialog v-if="unidadEditar === slotProps.data.ItemCode" headerClass="text-xs" header="Unidad de Medida" :closable="false" v-model:visible="modalEditUnidades" :style="{width: '30vw'}" :modal="true">
                          <div class="text-xs flex justify-center">
                            <Dropdown
                              panelClass="text-xs"
                              v-model="slotProps.data.unidadSeleccionada"
                              class="w-2/4 border border-gray-300 rounded-md"
                              optionLabel="description"
                              :options="slotProps.data.unidades_medida"
                              @change="cambiarUnidadMedidaInventario(slotProps.data)"
                              :class="validacionCampoRequerido('UseBaseUnits', slotProps.data.UseBaseUnits) ? 'border-red-600' : 'border-gray-300'"
                            />
                          </div>
                          <template #footer>
                            <Button @click="modalEditUnidades = false" label="Aceptar" />
                          </template>
                        </Dialog>
                      </p>
                    </div>
                    <span v-if="slotProps.data.Quantity === 0 && !slotProps.data.OnHandQty && creandoOrden" class="italic text-red-600 text-xs w-full ml-2">Este artículo debe tener un pendiente asignado</span>
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Cantidad" class="text-xs" headerClass="text-xs" :style="{'min-width': '8rem', 'max-width': '10rem'}">
              <template #body="slotProps">
                <div class="gap-0 text-xs grid grid-cols-2">
                  <p class="font-bold">Ent.</p>
                  <p class="font-bold text-green-600">{{ slotProps.data.DelivrdQty }}</p>
                  <p class="font-bold">Pend.</p>
                  <p class="font-bold text-red-600">{{ slotProps.data.OpenCreQty }}</p>
                  <p class="font-bold">Total</p>
                  <p class="font-bold text-gray-600">{{ slotProps.data.Quantity }}</p>
                </div>
              </template>
            </Column>
            <Column header="Prec. total" v-if="validarColumnasTabla('PriceTotal')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                {{ $h.formatCurrency(slotProps.data.UnitPrice * slotProps.data.Quantity) }}
              </template>
            </Column>
            <Column header="Seg. Ent." v-if="validarColumnasTabla('U_PHR_SeguiEntrega')" class="text-xs" headerClass="text-xs" :style="{'max-width': '7rem', 'min-width': '7rem'}">
              <template #body="slotProps">
                <Dropdown
                  panelClass="text-xs w-min" id="selectSeguimientoEntregas"
                  v-model="slotProps.data.U_PHR_SeguiEntrega"
                  filter
                  :class="validacionCampoRequerido('U_PHR_SeguiEntrega', slotProps.data.U_PHR_SeguiEntrega) ? 'border-red-600' : 'border-gray-300'"
                  class="w-full border rounded-md"
                  optionValue="Code"
                  optionLabel="Name"
                  :options="ordenesStore._listado_seguimientos_entrega"
                />
                <span v-if="validacionCampoRequerido('U_PHR_SeguiEntrega', slotProps.data.U_PHR_SeguiEntrega)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="No. Ent." v-if="validarColumnasTabla('U_PHR_NumEntregas')" class="text-xs w-min" headerClass="text-xs"  :style="{'max-width': '8rem'}">
              <template #body="slotProps">
                <InputNumber
                  :min="0"
                  :id="slotProps.data.ItemCode + 'U_PHR_NumEntregas'"
                  v-model="slotProps.data.U_PHR_NumEntregas"
                  inputClass="w-12"
                  :class="validacionCampoRequerido('U_PHR_NumEntregas', slotProps.data.U_PHR_NumEntregas) ? 'p-invalid' : 'border-gray-300'"
                />
                <span v-if="validacionCampoRequerido('U_PHR_NumEntregas', slotProps.data.U_PHR_NumEntregas)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Ex.Cuot" v-if="validarColumnasTabla('U_PHR_Exonerado') && ordenesStore._udf.porcentaje_cuota > 0" class="text-xs" headerClass="text-xs" :style="{'max-width': '5.5rem'}">
              <template #body="slotProps">
                <Dropdown
                  v-model="slotProps.data.U_PHR_Exonerado"
                  panelClass="text-xs"
                  :style="{'max-width': '5rem'}"
                  class="border rounded-md"
                  @change="ordenesStore.mostrar_calcular_cuota = true"
                  :options="arrSiNo"
                  optionLabel="name"
                  optionValue="value"
                  :class="validacionCampoRequerido('U_PHR_Exonerado', slotProps.data.U_PHR_Exonerado) ? 'border-red-600' : 'border-gray-300'"
                />
                <span v-if="validacionCampoRequerido('U_PHR_Exonerado', slotProps.data.U_PHR_Exonerado)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
              <span class="text-red-600 italic">Este campo es requerido</span>
            </Column>
            <Column header="Cant. Pres." v-if="validarColumnasTabla('U_PHR_CtdPrescrita')" class="text-xs" headerClass="text-xs" :style="{'max-width': '5rem'}">
              <template #body="slotProps">
                <InputNumber
                  :min="0"
                  id="integeronly"
                  inputClass="w-12"
                  v-model="slotProps.data.U_PHR_CtdPrescrita"
                  :class="validacionCampoRequerido('U_PHR_CtdPrescrita', slotProps.data.U_PHR_CtdPrescrita) ? 'p-invalid' : 'border-gray-300'"
                />
                <span v-if="validacionCampoRequerido('U_PHR_CtdPrescrita', slotProps.data.U_PHR_CtdPrescrita)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Fec. Pres." v-if="validarColumnasTabla('U_PHR_FecPrescrip')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <input
                  type="date"
                  :max="dayjs().format('YYYY-MM-DD')"
                  v-model="slotProps.data.U_PHR_FecPrescrip"
                  class="rounded-md p-1"
                  :style="validacionCampoRequerido('U_PHR_FecPrescrip', slotProps.data.U_PHR_FecPrescrip) ? {'border': '1px solid rgba(220, 38, 38, var(--tw-text-opacity))'} : ''"
                  >
                <span v-if="validacionCampoRequerido('U_PHR_FecPrescrip', slotProps.data.U_PHR_FecPrescrip)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Frec." v-if="validarColumnasTabla('U_PHR_Frecuencia')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <Dropdown
                  v-model="slotProps.data.U_PHR_Frecuencia"
                  :options="ordenesStore._listado_frecuencias"
                  optionLabel="Name"
                  optionValue="Code"
                  :class="validacionCampoRequerido('U_PHR_Frecuencia', slotProps.data.U_PHR_Frecuencia) ? 'border-red-600' : 'border-gray-300'"
                  filter
                  autoFilterFocus
                  panelClass="text-xs"
                  class="text-xs rounded-md"
                />
                <span v-if="validacionCampoRequerido('U_PHR_Frecuencia', slotProps.data.U_PHR_Frecuencia)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="D. tratam." v-if="validarColumnasTabla('U_PHR_DuraTratami')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <Dropdown
                  optionValue="Code"
                  optionLabel="Name"
                  panelClass="text-xs"
                  filter
                  autoFilterFocus
                  v-model="slotProps.data.U_PHR_DuraTratami"
                  class="w-full border rounded-md"
                  :class="validacionCampoRequerido('U_PHR_DuraTratami', slotProps.data.U_PHR_DuraTratami) ? 'border-red-600' : 'border-gray-300'"
                  :options="ordenesStore._listado_duraciones_tratamiento"
                />
                <span v-if="validacionCampoRequerido('U_PHR_DuraTratami', slotProps.data.U_PHR_DuraTratami)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Registro Médico/ Nombre Médico" v-if="validarColumnasTabla('U_PHR_RegMed')" class="text-xs" headerClass="text-xs" :style="{'min-width': '15rem'}">
              <template #body="slotProps">
                <AutoComplete
                  panelClass="text-xs w-20"
                  inputClass="text-xs border-0 m-0 ring-0 w-full h-full rounded-sm ring-red-600"
                  class="text-xs border m-0 ring-0 w-full h-full rounded-md"
                  :class="validacionCampoRequerido('U_PHR_RegMed', slotProps.data.U_PHR_RegMed) ? 'border-red-600' : 'border-gray-300'"
                  v-model="slotProps.data.U_PHR_RegMed"
                  :suggestions="listadoMedicos"
                  @complete="buscarMedicos($event)"
                  field='name'
                  :forceSelection="true"
                  placeholder="Mínimo 3 caracteres para buscar.."
                >
                  <template #item="slotProps">
                    <div :style="{'text-wrap': 'wrap'}">
                      <span>{{slotProps.item.name}}</span>
                    </div>
                  </template>
                </AutoComplete>
                <span v-if="validacionCampoRequerido('U_PHR_RegMed', slotProps.data.U_PHR_RegMed)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="IPS Prestadora Servicio" v-if="validarColumnasTabla('U_PHR_IPSPrest')" class="text-xs" headerClass="text-xs" :style="{'min-width': '15rem'}">
              <template #body="slotProps">
                <AutoComplete
                  panelClass="text-xs w-20"
                  inputClass="text-xs border-0 m-0 ring-0 w-full h-full rounded-sm ring-red-600"
                  class="text-xs border m-0 ring-0 w-full h-full rounded-md"
                  :class="validacionCampoRequerido('U_PHR_IPSPrest', slotProps.data.U_PHR_IPSPrest) ? 'border-red-600' : 'border-gray-300'"
                  v-model="slotProps.data.U_PHR_IPSPrest"
                  :suggestions="listadoIps"
                  @complete="buscarIps($event)"
                  field='NomIps'
                  :forceSelection="true"
                  placeholder="Mínimo 3 caracteres para buscar.."
                >
                  <template #item="slotProps">
                    <div :style="{'text-wrap': 'wrap'}">
                      <span>{{slotProps.item.NomIps}}</span>
                    </div>
                  </template>
                </AutoComplete>
                <span v-if="validacionCampoRequerido('U_PHR_IPSPrest', slotProps.data.U_PHR_IPSPrest)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="No. Auto." v-if="validarColumnasTabla('U_PHR_NumAutoriza')" class="text-xs w-min" headerClass="text-xs"  :style="{'max-width': '7rem', 'min-width': '7rem'}">
              <template #body="slotProps">
                <!-- <InputNumber
                  inputClass="w-full"
                  :class="validacionCampoRequerido('U_PHR_NumAutoriza', slotProps.data.U_PHR_NumAutoriza) ? 'border-red-600' : 'border-gray-300'"
                  v-model="slotProps.data.U_PHR_NumAutoriza"
                /> -->
                <input
                :class="validacionCampoRequerido('U_PHR_NumAutoriza', slotProps.data.U_PHR_NumAutoriza) ? 'border-red-600' : 'border-gray-300'"
                v-model="slotProps.data.U_PHR_NumAutoriza"
                class="w-full border rounded-md p-1"
                />
                <span v-if="validacionCampoRequerido('U_PHR_NumAutoriza', slotProps.data.U_PHR_NumAutoriza)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Fec. Auto." v-if="validarColumnasTabla('U_PHR_FecAutoriza')" class="text-xs w-min" headerClass="text-xs"  :style="{'max-width': '8rem'}">
              <template #body="slotProps">
                <input
                  type="date"
                  :max="dayjs().format('YYYY-MM-DD')"
                  v-model="slotProps.data.U_PHR_FecAutoriza"
                  :class="validacionCampoRequerido('U_PHR_FecAutoriza', slotProps.data.U_PHR_FecAutoriza) ? 'border-red-600' : 'border-gray-300'"
                >
                <span v-if="validacionCampoRequerido('U_PHR_FecAutoriza', slotProps.data.U_PHR_FecAutoriza)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Fec. Prescrip. Mipres"  v-if="validarColumnasTabla('U_PHR_FecPres')" class="text-xs w-min" headerClass="text-xs"  :style="{'max-width': '8rem'}">
              <template #body="slotProps">
                <input
                  type="date"
                  v-model="slotProps.data.U_PHR_FecPres"
                  class="rounded-md p-1"
                  :style="validacionCampoRequerido('U_PHR_FecPres', slotProps.data.U_PHR_FecPres) ? {'border': '1px solid rgba(220, 38, 38, var(--tw-text-opacity))'} : ''"
                >
                <span v-if="validacionCampoRequerido('U_PHR_FecPres', slotProps.data.U_PHR_FecPres)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <Column header="Junta médica" v-if="validarColumnasTabla('U_PHR_JunMedi')" class="text-xs" headerClass="text-xs" :style="{'max-width': '5rem'}">
              <template #body="slotProps">
                <Dropdown
                  v-model="slotProps.data.U_PHR_JunMedi"
                  panelClass="text-xs"
                  :style="{'max-width': '4.5rem'}"
                  class=" border rounded-md"
                  optionLabel="name"
                  optionValue="value"
                  :class="validacionCampoRequerido('U_PHR_JunMedi', slotProps.data.U_PHR_JunMedi) ? 'border-red-600' : 'border-gray-300'"
                  :options="arrSiNo"
                />
                <span v-if="validacionCampoRequerido('U_PHR_JunMedi', slotProps.data.U_PHR_JunMedi)" class="text-red-600 italic">Este campo es requerido</span>
              </template>
            </Column>
            <template #expansion="slotProps">
              <DataTable
                v-if="slotProps.data.StockMovements.length"
                :value="slotProps.data.StockMovements"
                responsiveLayout="scroll"
                editMode="cell"
                class="tablaLotes"
                :headerCheckboxToggleAllDisabled="true"
              >
                <Column header="Información del lote" class="text-xs" headerStyle="background: white;" :style="{width: '28rem'}">
                  <template #body="slotPropsChild">
                    <div class="flex gap-2">
                      <button @click="eliminarLotes(slotProps.data, slotPropsChild.data)"><i class="pi pi-trash text-red-600"></i></button>
                      <p class="font-bold">Lote:</p>
                      <p>{{ slotPropsChild.data.BatchNumber }}</p>
                      <p class="font-bold">Fec. venc.:</p>
                      <p>{{ slotPropsChild.data.FecVcto }}</p>
                      <p class="font-bold">Cant. Stock:</p>
                      <p>{{ slotPropsChild.data.cantstock_unitario }}</p>
                    </div>
                  </template>
                </Column>
                <Column field="Quantity" header="Cantidad entregar" class="text-xs" headerStyle="background: white;" headerClass="bg-white">
                  <template #body="slotPropsChild">
                    <div class="flex gap-2">
                      <input type="number" class="border rounded-md p-1" min="0" :id="slotPropsChild.data.Id" @focus="slotPropsChild.data.valor_anterior = slotPropsChild.data.Quantity" @change="validarCantidadesIngresadas(slotPropsChild.data, slotProps.data)" v-model="slotPropsChild.data.Quantity" />
                    </div>
                  </template>
                </Column>
              </DataTable>
              <p class="font-bold text-xs" v-else>No existen lotes seleccionados</p>
              <div v-if="slotProps.data.apartados.length" class="m-2 text-xs bg-blue-200 p-2 rounded-md w-min">
                <p class="font-medium">Medicamentos aprovechados</p>
                <div v-for="(linea, i) in slotProps.data.apartados" :key="i" class="w-56 grid grid-cols-2 gap-2 mt-2">
                  <div>
                    <p>Orden: <span class="font-bold">{{ linea.OvId }}</span></p>
                  </div>
                  <div>
                    <p>Cantidad: <span class="font-bold">{{ linea.Cantidad }}</span></p>
                  </div>
                </div>
              </div>
            </template>
          </DataTable>
          <div v-if="medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.some(a => a.OpenCreQty) : false" class="w-full my-2">
            <button @click="abrirModalListadoMedicamentosPendientes" class="flex gap-2 text-blue-600">
              <p class="text-blue-600">Artículos pendientes</p>
              <i class="pi pi-external-link"></i>
            </button>
          </div>
          <div class="w-full grid grid-cols-1 lg:grid-cols-3 mt-3">
            <div>
              <p class="text-gray-600 text-xs w-full">Comentarios:</p>
              <Textarea v-model="ordenesStore.footer.comentarios" class="w-full h-5/6" />
            </div>
            <div class="w-full flex lg:px-10 mt-3">
              <div class="w-6/12">
                <p class="text-xs text-gray-600">Creado por:</p>
                <p class="font-bold">{{ persona.full_name }}</p>
              </div>
              <div class="w-6/12">
                <p class="text-xs text-gray-600">Actualizado por:</p>
                <p class="font-bold">{{ $route.name === 'pharmasan.ventas.ov.editar' ? persona.full_name : '' }}</p>
              </div>
            </div>
            <div v-if="mostrarCalcularCuota">
              <div v-if="ordenesStore._udf.cuota_moderadora" class="w-full flex justify-end py-2 items-center gap-2">
                <p class="w-6/12 md:w-min">CUOTA:</p>
                <Dropdown class="font-bold w-3/12 text-center" v-model="ordenesStore.footer.exonera_cuota" :options="opcionesExonera" optionLabel="name" optionValue="id" />
              </div>
              <div class="flex justify-end my-2">
                <Button @click="calcularValorCuota" class="w-10/12 font-medium" label="CALCULAR CUOTA" />
              </div>
            </div>
            <div v-else>
              <div class="shadow px-2 rounded-md h-min text-xs" :style="{height: 'min-content'}">
                <div v-if="ordenesStore._udf.porcentaje_cuota" class="border-b flex justify-between items-center">
                  <p>Total cuota de Recuperación</p>
                  <div class="flex items-center">
                    <p class="">{{ $h.formatCurrency(ordenesStore._footer.total_cuota_recuperacion) }}</p>
                    <Button v-if="ordenesStore._udf.porcentaje_cuota" @click="ordenesStore.mostrar_calcular_cuota = true" icon="pi pi-replay" class="p-button-rounded p-button-text p-0" />
                  </div>
                </div>
                <div v-if="ordenesStore._udf.cuota_moderadora" class="flex justify-between">
                  <p class="p-2">Total cuota moderadora</p>
                  <div class="flex items-center">
                    <p>{{ $h.formatCurrency(ordenesStore._footer.total_cuota_moderadora) }}</p>
                    <Button v-if="ordenesStore._udf.cuota_moderadora" @click="ordenesStore.mostrar_calcular_cuota = true" icon="pi pi-replay" class="p-button-rounded p-button-text p-0" />
                  </div>
                </div>
              </div>
              <div class="md:flex mt-2 gap-2">
                <Button label="CREAR OV" @click="crearOv" class="p-button-success w-full font-bold mt-2 md:mt-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog headerClass="text-xs" header="Lotes disponibles" :closable="false" v-model:visible="displayModalMedicamentosAgrupados" :style="{width: '40vw'}" :modal="true">
      <div class="text-xs">
        <p>La cantidad que desea ingresar es mayor a la cantidad en stock del lote seleccionado, seleccione un lote para completar</p>
        <p class="my-2">Artículos pendientes por completar: <span class="font-bold" :class="cantidadFaltante() === 0 ? 'text-green-600' : 'text-red-600'">{{ cantidadFaltante() }}</span></p>
        <div class="border-6 border-b border-green-600" v-if="medicamentosAgrupados.filter(a => a.CodMx === medicamentoCompletarAgrupados.ItemCode).length">
          <div v-for="medicamento of medicamentosAgrupados.filter(a => a.CodMx === medicamentoCompletarAgrupados.ItemCode)" :key="medicamento.Id" class="p-field-checkbox mt-2">
            <div class="flex gap-2 text-xs p-1">
              <Checkbox :id="medicamento.NumLote" name="medicamento" :value="medicamento" v-model="medicamentosAgrupadosIngresar" />
              <div>
                <label>{{ medicamento.CodMx + ' - ' + medicamento.ItemName }}</label>
                <div class="flex gap-2">
                  <p class="font-bold">Lote: {{ medicamento.NumLote }}</p>
                  <p class="font-bold">Fecha de vencimiento: {{ medicamento.FecVcto }}</p>
                  <p class="font-bold">Stock: {{ medicamento.cantstock }}</p>
                </div>
              </div>
              <div class="block" v-if="medicamentosAgrupadosIngresar.some(a => a.NumLote === medicamento.NumLote)">
                <InputNumber :min="0" inputClass="p-inputtext-sm w-12" :max="cantidadFaltante() < medicamento.cantstock ? cantidadFaltante() : medicamento.cantstock" class="w-10 p-inputtext-sm" v-model="medicamento.cantidadAgg" />
              </div>
            </div>
          </div>
          <div class="p-1 gap-1 flex justify-end">
            <i class="pi pi-arrow-up text-xs text-green-600"></i>
            <p class="italic text-green-600">Estos artículos coinciden con el codigo del artículo que necesitas completar</p>
          </div>
        </div>
        <div v-if="medicamentosAgrupados.filter(a => a.CodMx !== medicamentoCompletarAgrupados.ItemCode).length">
          <div class="p-1 flex gap-1 justify-end">
            <i class="pi pi-arrow-down text-xs text-blue-600"></i>
            <p class="italic text-blue-600">Estos artículos contienen el mismo principio activo del artículo a completar</p>
          </div>
          <div v-for="medicamento of medicamentosAgrupados.filter(a => a.CodMx !== medicamentoCompletarAgrupados.ItemCode)" :key="medicamento.Id" class="p-field-checkbox mt-2">
            <div class="flex gap-2 text-xs p-1">
              <Checkbox :id="medicamento.NumLote" name="medicamento" :value="medicamento" v-model="medicamentosAgrupadosIngresar" />
              <div>
                <label>{{ medicamento.CodMx + ' - ' + medicamento.ItemName }}</label>
                <div class="flex gap-2">
                  <p class="font-bold">Lote: {{ medicamento.NumLote }}</p>
                  <p class="font-bold">Fecha de vencimiento: {{ medicamento.FecVcto }}</p>
                  <p class="font-bold">Stock: {{ medicamento.cantstock }}</p>
                </div>
              </div>
              <div class="block" v-if="medicamentosAgrupadosIngresar.some(a => a.NumLote === medicamento.NumLote)">
                <InputNumber :min="0" inputClass="p-inputtext-sm w-12" :max="medicamento.cantstock" class="w-10 p-inputtext-sm" v-model="medicamento.cantidadAgg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button :disabled="cantidadFaltante() !== 0" @click="ingresarMedicamentosAgrupados(0)" label="Guardar lotes" />
        <Button v-if=" (cantidadFaltante() > 0) && (medicamentosAgrupados.length === medicamentosAgrupadosIngresar.length) && !medicamentosAgrupadosIngresar.some(a => a.cantidadAgg !== a.cantstock)" @click="ingresarMedicamentosAgrupados(cantidadFaltante())" :label="`Guardar y crear ${cantidadFaltante()} pendientes`" />
        <Button class="p-button-secondary" @click="devolverCantidad(), cerrarModalAgrupados()" label="Cancelar" />
      </template>
    </Dialog>
    <Dialog headerClass="text-xs" header="Cantidad del artículo" :closable="false"
            v-model:visible="displayModalMedicamentoPendiente" :style="{width: '30vw'}" :modal="true">
      <p class="text-sm">Esta editando las cantidades del artículo {{ lotePendienteSeleccionado.ItemName }}</p>
      <div class="w-full grid grid-cols-2 gap-2 text-sm mt-4">
        <p>Entregado:</p>
        <p>{{ lotePendienteSeleccionado.DelivrdQty }}</p>
        <p>Pendiente:</p>
        <p>{{ lotePendienteSeleccionado.OpenCreQty }}</p>
        <p>Cantidad total:</p>
        <div class="flex gap-2">
          <div class="grid">
            <InputNumber class="w-24" inputClass="w-24 font-bold" :min="lotePendienteSeleccionado.DelivrdQty ? lotePendienteSeleccionado.DelivrdQty : 1" v-model="lotePendienteSeleccionado.Quantity"/>
            <span class="w-full text-xs italic text-gray-600">La cantidad minima ingresada debe ser la entregada, si deseas reducir entregados debe realizar la devolución</span>
          </div>
        </div>
      </div>
      <template #footer>
        <Button :disabled="lotePendienteSeleccionado.Quantity === (lotePendienteSeleccionado.DelivrdQty + lotePendienteSeleccionado.OpenCreQty)" @click="actualizarPendiente" label="Actualizar cantidad pendiente"/>
        <Button class="p-button-outlined p-button-secondary" @click="cerrarModalPendiente"
                label="Cancelar"/>
      </template>
    </Dialog>
    <Dialog headerClass="text-xs" header="Artículos pendientes" :closable="false" v-model:visible="displayModalMedicamentosPendientesListado" :style="{width: '30vw'}" :modal="true">
      <p class="text-sm mb-4">Listado de medicamentos pendientes al crear esta orden</p>
      <div v-if="listadoPendientesTotales.length">
        <div class="text-sm" v-for="(medicamento, i) in listadoPendientesTotales" :key="i">
          <div class="flex my-2 items-center">
            <p class="w-9/12">
              {{ medicamento.ItemCode + ' - ' + medicamento.ItemName }}
            </p>
            <div class="grid grid-cols-2 w-3/12">
              <InputNumber :min="1" inputClass="w-20 p-2" v-model="medicamento.OpenCreQty" />
              <button @click="eliminarPendiente(medicamento, true)"><i class="pi pi-trash text-red-600"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="font-bold">No cuentas con medicamentos pendientes</p>
      </div>
      <template #footer>
        <Button @click="actualizarListadoMedicamentosPendientes" label="Actualizar cantidades pendientes" />
        <Button class="p-button-outlined p-button-secondary" @click="cerrarModalListadoMedicamentosPendientes" label="Cancelar" />
      </template>
    </Dialog>
    <Dialog headerClass="text-xs" header="Reemplazar artículo" :closable="false" v-model:visible="displayModalReemplazarMd" :style="{width: '30vw'}" :modal="true">
      <div class="w-full">
        <div class="w-full">
          <AutoComplete
            @item-select="reemplazarMedicamento(medicamentoSeleccionado)"
            v-model="medicamentoSeleccionado"
            placeholder="Buscar medicamento (mínimo 3 caracteres)..."
            id="inputElement"
            inputClass="w-full text-xs"
            v-tooltip.top="'Para activar el buscador (Ctrl + y)'"
            panelClass="text-xs w-20"
            class="p-inputtext-sm my-4 w-full text-xs"
            :suggestions="medicamentosStore._listado_medicamentos.filter(a => a.CodMx !== medicamentoAReemplazar.ItemCode && a.cantstock > 0)"
            autofocus
            @complete="buscarMedicamentos($event)"
            :forceSelection="true"
            optionLabel="name"
          >
          <template #item="slotProps">
            <div
            class="flex gap-2 items-center p-1 rounded-md"
            >
              <img class="w-10" alt="Imagen del artículo" :src="`${baseUrl}/articulos/get-image/${slotProps.item.PicturName}`">
              <div v-if="slotProps.item.cantstock">
                <div>
                  <p class="p-0" :style="{'text-wrap': 'wrap'}">
                    {{ slotProps.item.CodMx + ' - ' + slotProps.item.ItemName + slotProps.item.UserText }}
                  </p>
                </div>
                <div class="flex items-center flex-wrap gap-x-1">
                  <p class="font-bold">Fecha de vencimiento:</p>
                  <p class="font-medium">{{ dayjs(slotProps.item.FecVcto).format('DD-MM-YYYY') }}</p>
                  <p class="font-bold">Lote:</p>
                  <p class="font-medium">{{ slotProps.item.NumLote }}</p>
                  <p class="font-bold">Precio:</p>
                  {{ slotProps.item.Price }}
                  <p :class="slotProps.item.Price ? 'text-green-600' : ''"
                      class="font-medium">{{
                      slotProps.item.Price ? $h.formatCurrency(slotProps.item.Price) : '...ESTE ARTÍCULO NO CUENTA CON CONTRATO PRECIO'
                    }}
                  </p>
                  <p class="font-bold">Stock total:</p>
                  <p class="font-medium">{{ slotProps.item.StockTotal }}</p>
                  <p class="font-bold">Disp.:</p>
                  <p class="font-medium p-1 rounded-md ">{{ slotProps.item.StockDisponible }}</p>
                  <p class="font-bold">Cant. lote:</p>
                  <p class="font-medium p-1 rounded-md ">{{ slotProps.item.cantstock }}</p>
                </div>
              </div>
              <div v-else>
                <p class="p-0">{{ slotProps.item.CodMx + ' - ' + slotProps.item.ItemName }}</p>
                <div class="flex gap-0 flex-wrap">
                  <p class="font-bold">Lote:</p>
                  <p class="font-medium">{{ slotProps.item.NumLote ? slotProps.item.NumLote : 'NO TIENE'  }}</p>
                  <p class="font-bold">Cant Stock:</p>
                  <p class="font-medium">0</p>
                </div>
              </div>
            </div>
          </template>
          </AutoComplete>
        </div>
      </div>
      <template #footer>
        <Button class="p-button-outlined p-button-secondary" @click="cerrarDisplayReemplazarMedicamento"
                label="Cancelar"/>
      </template>
    </Dialog>
    <Dialog :closable="false" v-model:visible="displayModalMedicamentosApartados" header="Artículos apartados" :style="{width: '30vw'}" :modal="true">
      <p class="text-sm">Intenta ingresar una cantidad mayor a la disponible, si desea aprovechar medicamento seleccione la orden de la cual desea aprovechar y la cantidad, de lo contrario, genere el pendiente</p>
      <p class="text-sm">Cantidad faltante: <span class="font-bold">{{ (mdApartados[0].cantidad_pendiente - lodash.sumBy(mdApartados, 'cantidad_enviar')) >= 0 ? mdApartados[0].cantidad_pendiente - lodash.sumBy(mdApartados, 'cantidad_enviar') : 0 }}</span></p>
      <div v-for="medicamento of mdApartados" :key="medicamento.Id" class="p-field-checkbox mt-2">
        <div class="flex items-center gap-2 text-xs p-1">
          <Checkbox :id="medicamento.Id" name="medicamento" @click=" medicamento.cantidad_enviar = 0" :value="medicamento" v-model="apartadosSend" class="my-auto"  />
          <div>
            <div class="flex gap-2">
              <p class="font-bold">Ov: {{ medicamento.OvId }}</p>
            </div>
            <label>{{ medicamento.ItemCode + ' - ' + medicamento.ItemName }}</label>
            <div class="flex gap-2">
              <!-- <p class="font-bold">Cant. Inicial Apartada: 60 {{ medicamento.Cantidad }}</p> -->
              <p class="font-bold">Cant. Apartada: {{ medicamento.CantidadRestante }}</p>
            </div>
          </div>
          <div v-if="apartadosSend.some(j => j.Id === medicamento.Id)">
            <InputNumber v-model="medicamento.cantidad_enviar" :min="0" :max="validarCantidadMaximaPorInputApartados(medicamento.CantidadRestante, mdApartados[0].cantidad_pendiente - lodash.sumBy(mdApartados, 'cantidad_enviar'))" inputClass="w-20" class="p-2 rounded-md w-20" type="number"/>
          </div>
        </div>
      </div>
      <template #footer>
        <Button :disabled="lodash.sumBy(mdApartados, 'cantidad_enviar') === 0" @click="seleccionarApartados(mdApartados[0].cantidad_pendiente - lodash.sumBy(mdApartados, 'cantidad_enviar'), lodash.sumBy(mdApartados, 'cantidad_enviar'))" :label=" mdApartados[0].cantidad_pendiente - lodash.sumBy(mdApartados, 'cantidad_enviar') >= 0 ? `Aprovechar ${lodash.sumBy(mdApartados, 'cantidad_enviar')} y crear ${mdApartados[0].cantidad_pendiente - lodash.sumBy(mdApartados, 'cantidad_enviar')} pendientes` : `Aprovechar ${lodash.sumBy(mdApartados, 'cantidad_enviar')} medicamentos`" />
        <Button class="p-button-outlined p-button-secondary" @click="cerrarModalApartados" label="No, Cancelar" />
      </template>
    </Dialog>
    <Dialog v-model:visible="desplayModalCrearOv" modal class="animation-duration-1000" :showHeader="false" :closable="false" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="text-center">
        <div class="w-full text-center">
          <div class="flex justify-center my-4">
            <div class="w-28 h-28 text-center items-center flex justify-center rounded-full border-2 border-green-400 scalein animation-duration-1000">
              <i class="pi pi-check text-green-400" :style="{'font-size': '4rem'}"></i>
            </div>
          </div>
          <p :style="{'font-size': '2rem'}" class="text-2xl font-medium mb-2">Guardado</p>
        </div>
        <div class="w-full my-2">
          <p>
            Orden creada con éxito con el número
            <span class="font-bold">{{ ordenesCreadas[0].Id }}</span>
            con motivo de autorización
            <span class="font-bold">{{ motivosAutorizacionStore._listado_motivos_autorizacion.find(a => a.Codigo === ordenesCreadas[0].U_PHR_MotAutoriza).Nombre }}</span>
          </p>
        </div>
        <div v-if="ordenesCreadas[0].DocEntry && medicamentosStore._medicamentos_tabla.some(a => a.OpenCreQty)" class="w-full">
          <p>No. pendiente: <span class="font-bold">{{ ordenesCreadas[0].DocEntry }}</span></p>
        </div>
        <div class="w-full flex justify-around mt-4">
          <Button @click="$router.push({ name: 'pharmasan.ventas.ov.listado' }), ordenesStore.resetData()" severity="info" label="Ir al listado" />
          <Button severity="warning" @click="ordenesStore.resetData(), desplayModalCrearOv = false" label="Nueva orden" />
          <Button severity="success" @click="imprimir(ordenesCreadas[0].Id)" label="Imprimir" />
        </div>
      </div>
    </Dialog>
    <VerMedicamento />
  </section>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useMedicosStore } from '../../../../../stores/medicos.store'
import { useIpsStore } from '../../../../../stores/ips.store'
import { useBodegasStore } from '../../../../../stores/bodegas.store'
import { useStore } from '@/store'
import { useMedicamentosStore } from '../../../../../stores/medicamentos.store'
import { useOrdenesStore } from '../../../../../stores/ordenes.store'
import { usePacientesStore } from '../../../../../stores/pacientes.store'
import { useMipresStore } from '../../../../../stores/mipres.store'
import MedicamentosService from '../../../../../services/medicamentos.service'
import { useMotivosAutorizacionStore } from '../../../../../stores/motivos_autorizacion.store'
import { useConfigGlobal } from '../../../../../stores/config-ov.store'
// import { useClientesStore } from '../../../../../stores/clientes.store'
import VerMedicamento from '../../../../../components/verMedicamento.vue'
import Cookies from 'js-cookie'
import axios from 'axios'
import lodash from 'lodash'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
export default {
  name: 'bodyPrimeVue',
  components: {
    VerMedicamento
  },
  setup () {
    // Servicios
    const _MedicamentosService = ref(new MedicamentosService())
    const toast = useToast()
    // Stores
    const _ipsStore = useIpsStore()
    const _medicosStore = useMedicosStore()
    const storePersona = useStore()
    const bodegasStore = useBodegasStore()
    const medicamentosStore = useMedicamentosStore()
    const ordenesStore = useOrdenesStore()
    const mipresStore = useMipresStore()
    // const clientesStore = useClientesStore()
    const pacientesStore = usePacientesStore()
    const motivosAutorizacionStore = useMotivosAutorizacionStore()
    // const calculoCuotaStore = useCalculoCuotaStore()
    // const router = useRouter()
    const configGlobal = useConfigGlobal()
    // Referencias
    const show = ref(true)
    const medicamentoSeleccionado = ref('')
    const displayModalReemplazarMd = ref(false)
    const numerosDireccionamiento = ref([])
    const displayModalMedicamentosAgrupados = ref(false)
    const displayModalMedicamentoPendiente = ref(false)
    const displayModalMedicamentosPendientesListado = ref(false)
    const displayModalMedicamentosApartados = ref(false)
    const medicamentosAgrupados = ref([])
    const medicamentosAgrupadosIngresar = ref([])
    const conteoMedicamentosPendientes = ref(0)
    const lotePendienteSeleccionado = ref()
    const listadoPendientesTotales = ref([])
    const medicamentoAReemplazar = ref()
    const modalEditUnidades = ref(false)
    const unidadEditar = ref(null)
    const mdApartados = ref({})
    const apartadosSend = ref([])
    const op = ref()
    const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV
    const medicamentoCompletarAgrupados = ref({})
    const lineasMdsSeleccionadas = ref([])
    const lineasLotesSeleccionados = ref([])
    const medicamentoTemporalIngresarApartado = ref({})
    const loteTemporalIngresarApartado = ref({})
    const loteTemporalIngresarAgrupado = ref({})
    const desplayModalCrearOv = ref(false)
    const ordenesCreadas = ref([])
    const cantTotalInicial = ref(0)
    // Data referencias
    const persona = storePersona.getters['auth/getPeople']
    const expandedRows = ref(null)
    const opcionesExonera = ref([
      {
        name: 'Exonera',
        id: '01'
      },
      {
        name: 'No exonera',
        id: '02'
      }
    ])
    const arrSiNo = ref([
      {
        name: 'Si',
        value: 'S'
      },
      {
        name: 'No',
        value: 'N'
      }
    ])
    // Computadas
    const rowData = computed(() => medicamentosStore._medicamentos_tabla)
    const bodegaSeleccionada = computed(() => bodegasStore._bodega_seleccionada)
    const listadoMedicos = computed(() => _medicosStore.listado_medicos)
    const listadoIps = computed(() => _ipsStore.listado_ips)
    const creandoOrden = computed(() => ordenesStore._creando_orden)
    const codigosMipres = computed(() => mipresStore._listado_codigos)
    const mostrarCalcularCuota = computed(() => ordenesStore._mostrar_calcular_cuota)
    const columnasTabla = computed(() => configGlobal._columnas_tabla)
    // Metodos
    const buscarMedicamentos = (event) => {
      if (!ordenesStore._header.cliente) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un cliente para continuar', life: 5000 })
      if (!ordenesStore._header.bodega) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una bodega para continuar', life: 5000 })
      if (!ordenesStore._udf.mot_autoriza) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un motivo de autorizacion para continuar', life: 5000 })
      if (ordenesStore._udf.portabilidad === 1 && !ordenesStore._udf.localizacion) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una localización para continuar', life: 5000 })
      if (!ordenesStore._udf.regimen) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un regimen para continuar', life: 5000 })
      if (!pacientesStore._paciente_seleccionado) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un paciente para continuar', life: 5000 })
      const object = {
        CardCode: ordenesStore._header.cliente.CardCode,
        ClienteId: ordenesStore._header.cliente.Id,
        WhsCode: bodegaSeleccionada.value.WhsCode,
        ModContrato: ordenesStore._udf.mot_autoriza.CodModContrato,
        MotAutoriza: ordenesStore._udf.mot_autoriza.Id,
        RegimenId: ordenesStore._udf.regimen,
        CodDane: ordenesStore._udf.portabilidad === 1 ? (ordenesStore._udf.localizacion ? ordenesStore._udf.localizacion.CodSap : 0) : ordenesStore._udf.cod_mpio
      }
      if (event.query.length > 2) {
        medicamentosStore.getListadoMedicamentos(object, event.query.toUpperCase())
      }
    }
    const validarIngresoMedicamento = async (medicamento) => {
      // Validación de medicamento sin contrato precio
      if (!medicamento.Price) {
        medicamentoSeleccionado.value = ''
        return toast.add({
          severity: 'error',
          summary: 'Error',
          detail: `El artículo ${medicamento.ItemName}
          no cuenta con precio, por favor valide con
          el contrato asociado al cliente ${ordenesStore._header.cliente.CardName}
          y vuelva a ingresar`,
          life: 10000
        })
      }
      let lote = {}
      if (medicamento.cantstock) {
        lote = {
          BatchNumber: medicamento.NumLote,
          FecVcto: medicamento.FecVcto,
          Quantity: 0,
          cantstock: medicamento.cantstock,
          ItemCode: medicamento.CodMx,
          WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
          ObjectType: 15,
          valor_anterior: 0,
          cantstock_unitario: medicamento.cantstock
        }
      }
      lote.cantstock_unitario = medicamento.cantstock
      if (medicamento.UnitMedida[0].value !== 1) {
        lote.cantstock = Math.trunc(medicamento.cantstock / medicamento.UnitMedida[0].value)
      }
      // Validación de entrega de un mismo medicamento en menos de 25 dias
      medicamentosStore.validarMedicamentosEntregadosMenores25Dias({ grupo: medicamento.GrpCode, U_ACS_TpoIdentf: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf, U_PHR_NumDcto: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf }).then((data) => {
        if (+data.count > 0) {
          toast.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: `${pacientesStore._paciente_seleccionado.U_ACS_Sexo === 'Femenino' ? 'La' : 'El'} paciente ${pacientesStore._paciente_seleccionado.U_ACS_NmbPct} recibio el medicamento ${object.name} hace menos de 25 dias`,
            life: 10000
          })
        }
      })
      // Buscar medico e ips del resto de medicamentos ingresados
      let medico = null
      let ips = null
      let numAutoriza = 'N/A'
      if (medicamentosStore.medicamentos_tabla.length) {
        for (const i of medicamentosStore.medicamentos_tabla) {
          i.U_PHR_RegMed ? medico = i.U_PHR_RegMed : medico = null
          i.U_PHR_IPSPrest ? ips = i.U_PHR_IPSPrest : ips = null
          i.U_PHR_NumAutoriza ? numAutoriza = i.U_PHR_NumAutoriza : numAutoriza = 'N/A'
        }
      }
      const object = {
        ItemCode: medicamento.CodMx,
        ItemName: medicamento.ItemName,
        GrpCode: medicamentoSeleccionado.value.GrpCode,
        UseBaseUnits: medicamento.UnitMedida[0].code,
        UnitsOfMeasurment: medicamento.UnitMedida[0].value,
        WarehouseCode: bodegasStore._bodega_seleccionada.WhsCode,
        U_PHR_CtoAsociado: medicamento.U_PHR_CtoAsociado,
        UnitPrice: medicamento.Price ? parseInt(medicamento.Price) : null,
        U_PHR_SeguiEntrega: null,
        U_PHR_NumEntregas: 0,
        U_PHR_Exonerado: null,
        U_PHR_CuotaRecupe: null,
        U_PHR_CtdPrescrita: 0,
        U_PHR_FecPrescrip: '',
        U_PHR_Frecuencia: null,
        U_PHR_DuraTratami: null,
        U_PHR_CdHomologo: null,
        U_PHR_NomHomologo: null,
        U_PHR_CntHomologo: null,
        U_PHR_PrHomologo: null,
        U_PHR_TotalHomologo: null,
        U_PHR_NumAutoriza: numAutoriza,
        U_PHR_FecAutoriza: '',
        U_PHR_NoAcCTC: null,
        U_PHR_FchAcCTC: null,
        U_PHR_FchSolActCTC: null,
        U_PHR_CodMipres: null,
        U_PHR_NumDirec: null,
        U_PHR_FecPres: '',
        U_PHR_JunMedi: null,
        U_PHR_Siniestro: null,
        CostingCode: bodegaSeleccionada.value.U_PHR_CentroCosto,
        U_PHR_Sta_Pen: null,
        FreeText: null,
        U_PHR_IdPrincipal: null,
        LineNum: medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1,
        LineStatus: medicamento.cantstock ? 1 : 0,
        U_PHR_RegMed: medico,
        U_PHR_IPSPrest: ips,
        DelivrdQty: 0,
        OpenCreQty: 0,
        Quantity: 0,
        unidades_medida: medicamento.UnitMedida,
        unidadSeleccionada: { ...medicamento.UnitMedida[0] },
        name: medicamento.name,
        StockMovements: medicamento.cantstock ? [lote] : [],
        apartados: [],
        OnHandQty: medicamento.cantstock ? medicamento.cantstock : 0,
        StockTotal: medicamento.StockTotal,
        StockDisponible: medicamento.StockDisponible,
        ArticuloId: medicamento.ArticuloId,
        stock_total_unitario: medicamento.StockTotal,
        stock_disponible_unitario: medicamento.StockDisponible,
        precio_unitario: medicamento.Price ? parseInt(medicamento.Price) : null,
        ContratoId: medicamento.ContratoId
      }
      if (object.UnitsOfMeasurment !== 1) {
        object.UnitPrice = object.UnitPrice * object.UnitsOfMeasurment
        object.StockTotal = Math.trunc(object.StockTotal / object.UnitsOfMeasurment)
        object.StockDisponible = Math.trunc(object.StockDisponible / object.UnitsOfMeasurment)
      }
      // Validacion de agrupados si el medicamento no tiene stock
      if (!medicamento.cantstock) {
        const agrupados = await _obtenerAgrupados(medicamento.GrpCode)
        if (agrupados.length) {
          const agrupado = agrupados[0]
          return Swal.fire({
            title: 'Atención',
            text: `El artículo que intentas ingresar
            no cuenta con stock pero existe un artículo
            agrupado con stock, ¿Deseas ingresar el artículo agrupado ${agrupado.CodMx}?`,
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Agregar agrupado',
            denyButtonText: 'Crear pendiente',
            denyButtonColor: '#db8e00',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              const loteAgrupado = {
                BatchNumber: agrupado.cantstock > 0 ? agrupado.NumLote : null,
                FecVcto: agrupado.cantstock > 0 ? agrupado.FecVcto : null,
                Quantity: 0,
                cantstock: agrupado.cantstock,
                ItemCode: agrupado.CodMx,
                WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
                ObjectType: 15,
                valor_anterior: 0,
                cantstock_unitario: agrupado.cantstock
              }
              object.NumLote = agrupado.NumLote
              object.ItemCode = agrupado.CodMx
              object.UnitPrice = agrupado.Price
              object.OnHandQty = agrupado.cantstock
              object.ItemName = agrupado.ItemName
              object.FecVcto = agrupado.FecVcto
              object.name = agrupado.name
              object.UseBaseUnits = agrupado.UnitMedida[0].code
              object.UnitsOfMeasurment = agrupado.UnitMedida[0].value
              object.unidadSeleccionada = agrupado.UnitMedida[0]
              object.ItemDescription = agrupado.UserText
              object.unidades_medida = agrupado.UnitMedida
              object.U_PHR_Nivel = agrupado.U_PHR_Nivel
              object.pendiente = false
              object.LineStatus = 1
              object.LineNum = medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1
              object.StockMovements = [loteAgrupado]
              object.StockTotal = agrupado.StockTotal
              object.StockDisponible = agrupado.StockDisponible
              object.ArticuloId = agrupado.ArticuloId
              object.stock_total_unitario = agrupado.StockTotal
              object.stock_disponible_unitario = agrupado.StockDisponible
              object.precio_unitario = medicamento.Price ? parseInt(medicamento.Price) : null
              ingresarMedicamentoTabla(object, loteAgrupado)
            } else if (result.isDenied) {
              ingresarMedicamentoTabla(object, lote)
            } else {
              medicamentoSeleccionado.value = ''
            }
          })
        }
      }
      ingresarMedicamentoTabla(object, lote)
    }
    const ingresarMedicamentoTabla = (medicamento, lote) => {
      if (medicamentosStore._medicamentos_tabla.length && medicamentosStore._medicamentos_tabla.some(a => a.ItemCode === medicamento.ItemCode)) {
        medicamentosStore._medicamentos_tabla.map(a => {
          if (a.ItemCode === medicamento.ItemCode) {
            a.StockMovements.push(lote)
            a.OnHandQty += lote.cantstock
          }
        })
      } else {
        if (lote.cantstock) {
          medicamentosStore.medicamentos_tabla.splice(0, 0, medicamento)
        } else {
          asignarPendiente(medicamento, 1)
        }
      }
      _sumarCantidades(medicamento)
      medicamentoSeleccionado.value = ''
    }
    const asignarPendiente = (medicamento, cantidad) => {
      if (medicamento.StockDisponible !== medicamento.StockTotal && (_sumaLotesIngresados(medicamento.StockMovements) + cantidad) >= medicamento.StockDisponible) {
        const pendientes = _sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible + cantidad
        Swal.fire({
          title: 'Advertencia',
          text: `La cantidad que deseas ingresar es superior a la cantidad en stock general de la bodega de ${medicamento.ItemName}, deseas crear un pendiente por ${pendientes} o deseas aprovechar de apartados?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Crear pendiente',
          cancelButtonText: 'Aprovechar apartados',
          cancelButtonColor: '#F59E0B'
        }).then((result) => {
          if (result.isConfirmed) {
            if (medicamentosStore._medicamentos_tabla.length && medicamentosStore._medicamentos_tabla.some(a => a.ItemCode === medicamento.ItemCode)) {
              if (medicamento.OpenCreQty) {
                Swal.fire({
                  title: 'Advertencia',
                  text: `El medicamento ${medicamento.ItemName}, ya cuenta con una cantidad pendiente, deseas modificarla?`,
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Modificar pendiente',
                  cancelButtonText: 'Cancelar'
                }).then((result2) => {
                  if (result2.isConfirmed) {
                    medicamentosStore._medicamentos_tabla.map(a => {
                      if (a.ItemCode === medicamento.ItemCode) {
                        a.OpenCreQty = pendientes
                      }
                    })
                  }
                })
              } else {
                let cantidadRestar = _sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible
                medicamentosStore._medicamentos_tabla.map(a => {
                  if (a.ItemCode === medicamento.ItemCode) {
                    a.OpenCreQty = pendientes
                    a.StockMovements.map(j => {
                      while (j.Quantity > 0 && cantidadRestar > 0) {
                        j.Quantity -= 1
                        cantidadRestar -= 1
                      }
                    })
                  }
                })
              }
            } else {
              medicamento.Quantity += pendientes
              medicamento.OpenCreQty = pendientes
              medicamento.apartados = []
              medicamentosStore.medicamentos_tabla.splice(0, 0, medicamento)
            }
            _sumarCantidades(medicamento)
            Swal.fire(
              'Guardado',
              'Pendiente creado con éxito',
              'success'
            )
          } else {
            // getApartado(medicamento.ItemCode, cantidad)
            getApartado(medicamento.ItemCode, pendientes)
            medicamentoTemporalIngresarApartado.value = medicamento
          }
        })
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: `La cantidad que deseas ingresar es superior a la cantidad en stock general de la bodega de ${medicamento.ItemName}, deseas crear un pendiente por ${cantidad}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Crear pendiente',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            if (medicamentosStore._medicamentos_tabla.length && medicamentosStore._medicamentos_tabla.some(a => a.ItemCode === medicamento.ItemCode)) {
              if (medicamento.OpenCreQty) {
                Swal.fire({
                  title: 'Advertencia',
                  text: `El medicamento ${medicamento.ItemName}, ya cuenta con una cantidad pendiente, deseas modificarla?`,
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Modificar pendiente',
                  cancelButtonText: 'Cancelar'
                }).then((result2) => {
                  if (result2.isConfirmed) {
                    medicamentosStore._medicamentos_tabla.map(a => {
                      if (a.ItemCode === medicamento.ItemCode) {
                        a.OpenCreQty = cantidad
                      }
                    })
                  }
                })
              } else {
                medicamentosStore._medicamentos_tabla.map(a => {
                  if (a.ItemCode === medicamento.ItemCode) {
                    a.OpenCreQty = cantidad
                  }
                })
              }
            } else {
              medicamento.Quantity += cantidad
              medicamento.OpenCreQty = cantidad
              medicamento.apartados = []
              medicamentosStore.medicamentos_tabla.splice(0, 0, medicamento)
            }
            _sumarCantidades(medicamento)
            Swal.fire(
              'Guardado',
              'Pendiente creado con éxito',
              'success'
            )
          }
        })
      }
    }
    const getApartado = (md, cantidadPendiente) => {
      _MedicamentosService.value.getApartadoByMd(md).then(({ data }) => {
        mdApartados.value = []
        for (const i of data) {
          if (!mdApartados.value.length || !mdApartados.value.some(e => e.Id === i.Id)) {
            mdApartados.value.push({
              OvId: i.OvId,
              OvDtId: i.OvDtId,
              Cantidad: i.Cantidad,
              CantidadRestante: i.CantidadRestante,
              ItemCode: i.ItemCode,
              ItemName: i.ItemName,
              Id: i.Id,
              cantidad_enviar: 0,
              cantidad_pendiente: cantidadPendiente
            })
          }
        }
        displayModalMedicamentosApartados.value = true
      })
    }
    const seleccionarApartados = (pendientes, aprovechados) => {
      const array = []
      for (const i of apartadosSend.value) {
        array.push({
          OvId: i.OvId,
          OvDtId: i.OvDtId,
          Cantidad: i.cantidad_enviar,
          ApaId: i.Id
        })
      }
      medicamentosStore._medicamentos_tabla.map(e => {
        if (e.ItemCode === medicamentoTemporalIngresarApartado.value.ItemCode) {
          if (medicamentosAgrupadosIngresar.value.length) {
            medicamentosAgrupadosIngresar.value.map(a => {
              const object = {
                BatchNumber: a.NumLote,
                FecVcto: a.FecVcto,
                Quantity: a.cantidadAgg,
                cantstock: a.cantstock,
                ItemCode: a.CodMx,
                WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
                ObjectType: 15,
                valor_anterior: a.cantidadAgg,
                cantstock_unitario: a.cantstock
              }
              const mxIngreso = medicamentoCompletarAgrupados.value.ItemCode === object.ItemCode ? medicamentoCompletarAgrupados.value : {
                ...medicamentoCompletarAgrupados.value,
                ItemCode: a.CodMx,
                id_ovdt: null,
                ItemName: a.ItemName,
                UseBaseUnits: a.UnitMedida[0].code,
                UnitsOfMeasurment: a.UnitMedida[0].value,
                UnitPrice: a.Price,
                ArticuloId: a.ArticuloId || null,
                precio_unitario: a.Price,
                StockTotal: a.StockTotal,
                stock_disponible_unitario: a.StockDisponible,
                stock_total_unitario: a.StockTotal,
                Quantity: a.cantidadAgg,
                Id: null,
                FecVcto: a.FecVcto,
                DelivrdQty: a.cantidadAgg,
                OpenCreQty: 0,
                unidades_medida: a.UnitMedida,
                apartados: [],
                name: a.name,
                LineNum: medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1,
                LineStatus: a.cantstock ? 1 : 0,
                unidadSeleccionada: { ...a.UnitMedida[0] },
                StockMovements: [object]
              }
              ingresarMedicamentoTabla(mxIngreso, object)
            })
            _ajustarCantidadesDisponibles(e)
            let cantidadSumar = aprovechados
            e.StockMovements.map(j => {
              while (j.Quantity < j.cantstock && cantidadSumar > 0) {
                cantidadSumar -= 1
                j.Quantity += 1
              }
            })
            e.StockMovements = e.StockMovements.filter(a => a.Quantity)
            cerrarModalAgrupados()
          } else {
            let cantidadSumar = aprovechados
            e.StockMovements.map(j => {
              while (j.Quantity < j.cantstock && cantidadSumar > 0) {
                cantidadSumar -= 1
                j.Quantity += 1
              }
            })
          }
          e.OpenCreQty = pendientes
          e.apartados = array
          _sumarCantidades(e)
        }
      })
      loteTemporalIngresarApartado.value = {}
      displayModalMedicamentosApartados.value = false
      medicamentoSeleccionado.value = ''
      apartadosSend.value = []
      toast.add({ severity: 'info', summary: 'Atención', detail: 'Se aprovecharon cantidades apartadas', life: 5000 })
    }
    const cerrarModalApartados = () => {
      displayModalMedicamentosApartados.value = false
      medicamentoSeleccionado.value = ''
      apartadosSend.value = []
      devolverCantidad()
    }
    const validarCantidadesIngresadas = async (dataLote, medicamento) => {
      if (dataLote.Quantity < 0) {
        dataLote.Quantity = 0
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'La cantidad no puede ser menor a 0', life: 3000 })
      }
      cantTotalInicial.value = _sumaLotesIngresados(medicamento.StockMovements)
      if (ordenesStore._udf.porcentaje_cuota) {
        ordenesStore.mostrar_calcular_cuota = true
      }
      if (medicamento.StockTotal !== medicamento.StockDisponible && dataLote.Quantity < dataLote.cantstock) {
        _validarApartados(dataLote, medicamento)
      } else {
        if (dataLote.Quantity > dataLote.cantstock) {
          const cantidadPendiente = dataLote.Quantity - dataLote.cantstock
          if (medicamento.StockMovements.length === 1) {
            _validacionAgrupados(cantidadPendiente, medicamento, dataLote)
          } else {
            let cantidadRestante = 0
            medicamento.StockMovements.map(a => {
              if (!(a.BatchNumber === dataLote.BatchNumber)) {
                cantidadRestante += a.cantstock - a.Quantity
              }
            })
            if (cantidadRestante > 0) {
              const sumaCantidadesIngresadas = _sumaLotesIngresados(medicamento.StockMovements)
              await Swal.fire({
                title: 'Atención',
                icon: 'question',
                text: 'Intentas ingresar una cantidad mayor a la cantidad en stock, deseas completar las cantidades requeridas con los demas lotes?',
                showCancelButton: true,
                confirmButtonText: 'Completar cantidades',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  if (medicamento.StockDisponible !== medicamento.StockTotal && sumaCantidadesIngresadas > medicamento.StockDisponible) {
                    Swal.fire({
                      title: 'Advertencia',
                      icon: 'warning',
                      text: `La cantidad que deseas ingresar supera la cantidad disponible
                      del medicamento, ¿deseas crear pendiente por
                      ${_sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible}? o deseas aprovechar medicamentos`,
                      showCancelButton: true,
                      showDenyButton: true,
                      confirmButtonText: 'Crear pendiente',
                      denyButtonText: 'Aprovechar medicamentos',
                      cancelButtonText: 'Cancelar',
                      denyButtonColor: '#F59E0B'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        medicamento.OpenCreQty = dataLote.Quantity - dataLote.cantstock
                        dataLote.Quantity = dataLote.cantstock
                        medicamento.apartados = []
                        apartadosSend.value = []
                        _sumarCantidades(medicamento)
                        Swal.fire(
                          'Guardado',
                          'Pendiente creado con éxito',
                          'success'
                        )
                      } else if (result.isDenied) {
                        if (cantidadRestante >= cantidadPendiente) {
                          let descuentoCantidad = cantidadPendiente
                          dataLote.Quantity = dataLote.cantstock
                          medicamento.StockMovements.map(a => {
                            while (descuentoCantidad > 0 && a.BatchNumber !== dataLote.BatchNumber && a.cantstock > a.Quantity) {
                              a.Quantity += 1
                              descuentoCantidad -= 1
                            }
                          })
                          medicamento.apartados = []
                          getApartado(medicamento.ItemCode, _sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible)
                          medicamento.OpenCreQty = 0
                          medicamentoTemporalIngresarApartado.value = medicamento
                          loteTemporalIngresarApartado.value = dataLote
                          _ajustarCantidadesDisponibles(medicamento)
                        } else {
                          dataLote.Quantity = dataLote.cantstock
                          medicamento.StockMovements.map(a => {
                            a.Quantity = a.cantstock
                          })
                          _validacionAgrupados(cantidadPendiente - cantidadRestante, medicamento, dataLote)
                        }
                      } else {
                        dataLote.Quantity = dataLote.valor_anterior
                        _sumarCantidades(medicamento)
                        return true
                      }
                      return false
                    })
                  } else {
                    if (cantidadRestante >= cantidadPendiente) {
                      let descuentoCantidad = cantidadPendiente
                      dataLote.Quantity = dataLote.cantstock
                      medicamento.StockMovements.map(a => {
                        while (descuentoCantidad > 0 && a.BatchNumber !== dataLote.BatchNumber) {
                          if (a.cantstock > a.Quantity) {
                            a.Quantity += 1
                            descuentoCantidad -= 1
                          }
                        }
                      })
                    } else {
                      dataLote.Quantity = dataLote.cantstock
                      medicamento.StockMovements.map(a => {
                        a.Quantity = a.cantstock
                      })
                      return _validacionAgrupados(cantidadPendiente - cantidadRestante, medicamento, dataLote)
                    }
                    _sumarCantidades(medicamento)
                  }
                } else {
                  dataLote.Quantity = dataLote.cantstock
                  toast.add({ severity: 'warn', summary: 'Alerta', detail: 'Se asigno la cantidad máxima del lote', life: 5000 })
                  _sumarCantidades(medicamento)
                }
              })
            } else {
              _validacionAgrupados(cantidadPendiente, medicamento, dataLote)
            }
          }
        } else {
          if (dataLote.Quantity < dataLote.cantstock && medicamento.OpenCreQty) {
            Swal.fire({
              title: 'Advertencia!',
              icon: 'warning',
              text: 'Al disminuir la cantidad de este artículo se eliminaran los pendientes, ¿Estas seguro?',
              showCancelButton: true,
              confirmButtonText: 'Eliminar pendientes',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                medicamento.OpenCreQty = 0
                _sumarCantidades(medicamento)
              } else {
                dataLote.Quantity = dataLote.cantstock
              }
            })
          } else if (dataLote.Quantity < dataLote.cantstock && medicamento.apartados.length) {
            if (_sumaLotesIngresados(medicamento.StockMovements) >= medicamento.StockDisponible) {
              Swal.fire({
                title: 'Advertencia!',
                icon: 'warning',
                text: 'Al disminuir la cantidad de este artículo se eliminaran los apartados, ¿Estas seguro?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar apartados',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  medicamento.apartados = []
                  apartadosSend.value = []
                } else {
                  dataLote.Quantity = dataLote.valor_anterior
                }
              })
            }
          } else {
            if (medicamento.StockTotal !== medicamento.StockDisponible) {
              _validarApartados(dataLote, medicamento)
            } else {
              _sumarCantidades(medicamento)
            }
          }
        }
      }
    }
    const _sumaLotesIngresados = (lotes) => {
      let sum = 0
      lotes.map(e => {
        sum += e.Quantity
      })
      return sum
    }
    const _validarApartados = async (dataLote, medicamento) => {
      if (_sumaLotesIngresados(medicamento.StockMovements) > medicamento.StockDisponible) {
        await Swal.fire({
          title: 'Advertencia',
          icon: 'warning',
          text: `La cantidad que deseas ingresar supera la cantidad disponible
          del medicamento, ¿deseas crear pendiente por
          ${_sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible}? o deseas aprovechar medicamentos`,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Crear pendiente',
          denyButtonText: 'Aprovechar medicamentos',
          cancelButtonText: 'Cancelar',
          denyButtonColor: '#F59E0B'
        }).then((result) => {
          if (result.isConfirmed) {
            medicamento.OpenCreQty = _sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible
            dataLote.Quantity = dataLote.Quantity - (_sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible)
            medicamento.apartados = []
            _sumarCantidades(medicamento)
            Swal.fire(
              'Guardado',
              'Pendiente creado con éxito',
              'success'
            )
          } else if (result.isDenied) {
            getApartado(medicamento.ItemCode, _sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible)
            if (dataLote.Quantity > (_sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible)) {
              dataLote.Quantity = dataLote.Quantity - (_sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible)
            } else {
              medicamento.StockMovements.map(e => {
                let restar = (_sumaLotesIngresados(medicamento.StockMovements) - medicamento.StockDisponible)
                while (restar > 0 && e.Quantity > 0) {
                  restar -= 1
                  e.Quantity -= 1
                }
              })
            }
            medicamento.OpenCreQty = 0
            medicamentoTemporalIngresarApartado.value = medicamento
            loteTemporalIngresarApartado.value = dataLote
            return true
          } else {
            dataLote.Quantity = dataLote.valor_anterior ? dataLote.valor_anterior : 0
            _sumarCantidades(medicamento)
            return true
          }
          return false
        })
      } else {
        medicamento.apartados = []
        _sumarCantidades(medicamento)
      }
    }
    const _validacionAgrupados = async (pendientes, medicamento, dataLote) => {
      const agrupados = await _obtenerAgrupados(medicamento.GrpCode)
      const array = []
      if (agrupados.length) {
        for (const i of agrupados) {
          if (medicamentosStore.medicamentos_tabla.some(a => a.ItemCode === i.CodMx)) {
            if (!medicamentosStore.medicamentos_tabla.find(a => a.ItemCode === i.CodMx).StockMovements.some(a => a.BatchNumber === i.NumLote)) {
              array.push(i)
            }
          } else {
            array.push(i)
          }
        }
      }
      array.map(a => {
        a.cantidadAgg = 0
      })
      dataLote.Quantity = dataLote.cantstock
      if (agrupados.length) {
        medicamentosAgrupados.value = []
        const temp = []
        for (const i of agrupados) {
          if (i.CodMx !== medicamento.ItemCode) {
            if (temp.length) {
              if (temp.some(a => a.mx === i.CodMx)) {
                temp.find(a => a.mx === i.CodMx).lotes.push(i)
              } else {
                temp.push({
                  mx: i.CodMx,
                  disp: i.StockDisponible,
                  total: i.StockTotal,
                  lotes: [i]
                })
              }
            } else {
              temp.push({
                mx: i.CodMx,
                disp: i.StockDisponible,
                total: i.StockTotal,
                lotes: [i]
              })
            }
          } else {
            medicamentosAgrupados.value.push(i)
          }
        }
        for (const i of temp) {
          if (i.total !== i.disp) {
            let descontar = i.total - i.disp
            for (const j of i.lotes) {
              j.cant_stock_real = j.cantstock
              while (j.cantstock > 0 && descontar > 0) {
                descontar -= 1
                j.cantstock -= 1
              }
              if (j.cantstock > 0) {
                medicamentosAgrupados.value.push(j)
              }
            }
          } else {
            i.lotes.forEach(j => {
              medicamentosAgrupados.value.push(j)
            })
          }
        }
        if (medicamentosAgrupados.value.length) {
          medicamentoCompletarAgrupados.value = medicamento
          medicamentoCompletarAgrupados.value.lote = dataLote
          loteTemporalIngresarAgrupado.value = dataLote
          conteoMedicamentosPendientes.value = pendientes
          displayModalMedicamentosAgrupados.value = true
          if (medicamento.StockTotal !== medicamento.StockDisponible) {
            loteTemporalIngresarApartado.value = dataLote
            _ajustarCantidadesDisponibles(medicamento, dataLote)
          }
        } else {
          asignarPendiente(medicamento, pendientes)
        }
      } else {
        asignarPendiente(medicamento, pendientes)
      }
    }
    const _ajustarCantidadesDisponibles = (medicamento) => {
      const cantidadDisponible = medicamento.StockDisponible
      medicamento.StockMovements.map(a => {
        a.Quantity = 0
      })
      let asignados = 0
      medicamento.StockMovements.map(a => {
        while (asignados !== cantidadDisponible && a.cantstock > a.Quantity) {
          asignados += 1
          a.Quantity += 1
        }
      })
    }
    const _obtenerAgrupados = async (agrupado) => {
      if (!ordenesStore._header.cliente) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un cliente para continuar', life: 5000 })
      if (!ordenesStore._header.bodega) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una bodega para continuar', life: 5000 })
      if (!ordenesStore._udf.mot_autoriza) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un motivo de autorizacion para continuar', life: 5000 })
      if (ordenesStore._udf.portabilidad === 1 && !ordenesStore._udf.localizacion) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una localización para continuar', life: 5000 })
      if (!ordenesStore._udf.regimen) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un regimen para continuar', life: 5000 })
      if (!pacientesStore._paciente_seleccionado) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar un paciente para continuar', life: 5000 })
      const obectGetAgrupados = {
        CardCode: ordenesStore._header.cliente.CardCode,
        WhsCode: ordenesStore._header.bodega,
        ModContrato: ordenesStore._udf.mot_autoriza.CodModContrato,
        MotAutoriza: ordenesStore._udf.mot_autoriza.Id,
        ClienteId: ordenesStore._header.cliente.Id,
        Agrupado: agrupado,
        RegimenId: ordenesStore._udf.regimen,
        CodDane: ordenesStore._udf.portabilidad === 1 ? (ordenesStore._udf.localizacion ? ordenesStore._udf.localizacion.CodSap : 0) : ordenesStore._udf.cod_mpio
      }
      const data = await medicamentosStore.getListadoMedicamentos(obectGetAgrupados, '')
      const medicamentosAgrupadosDisponibles = []
      if (data.length) {
        for (const i of data) {
          if (i.cantstock > 0 && i.Price) {
            medicamentosAgrupadosDisponibles.push(i)
          }
        }
      }
      return Promise.resolve(medicamentosAgrupadosDisponibles)
    }
    const _sumaLotesAgrupadosIngresados = (lotes) => {
      let sum = 0
      lotes.map(e => {
        sum += e.cantidadAgg
      })
      return sum
    }
    const ingresarMedicamentosAgrupados = async (pendientes) => {
      const agrupadoDisp = medicamentoCompletarAgrupados.value.StockDisponible
      const agrupadoStockTotal = medicamentoCompletarAgrupados.value.StockTotal
      const agrupadoStockDisp = medicamentoCompletarAgrupados.value.StockDisponible
      if (agrupadoDisp !== agrupadoStockTotal && cantTotalInicial.value > agrupadoStockDisp) {
        let cantidad = cantTotalInicial.value - agrupadoStockDisp - _sumaLotesAgrupadosIngresados(medicamentosAgrupadosIngresar.value.filter(a => a.CodMx !== medicamentoCompletarAgrupados.value.ItemCode))
        if (cantTotalInicial.value > agrupadoStockTotal) {
          cantidad += _sumaLotesAgrupadosIngresados(medicamentosAgrupadosIngresar.value.filter(a => a.CodMx === medicamentoCompletarAgrupados.value.ItemCode))
        }
        Swal.fire({
          title: 'Advertencia',
          icon: 'warning',
          text: `La cantidad que deseas ingresar supera la cantidad disponible
          del medicamento, ¿deseas crear pendiente por
          ${cantidad}? o deseas aprovechar medicamentos`,
          showCancelButton: true,
          confirmButtonText: 'Crear pendiente',
          cancelButtonText: 'Aprovechar medicamentos',
          cancelButtonColor: '#F59E0B'
        }).then((result) => {
          if (result.isConfirmed) {
            for (const medicamento of medicamentosStore._medicamentos_tabla) {
              if (medicamento.ItemCode === medicamentoCompletarAgrupados.value.ItemCode) {
                medicamento.OpenCreQty += cantTotalInicial.value > agrupadoStockTotal ? agrupadoStockTotal - agrupadoStockDisp : cantidad
                medicamento.OpenCreQty += pendientes || 0
                _sumarCantidades(medicamento)
              }
            }
            // let temp = cantTotalInicial.value > agrupadoStockTotal ? agrupadoStockTotal - agrupadoStockDisp : cantidad
            let temp = 0
            if (_sumaLotesAgrupadosIngresados(medicamentosAgrupadosIngresar.value.filter(a => a.CodMx === medicamentoCompletarAgrupados.value.ItemCode)) > 0 && cantTotalInicial.value > agrupadoStockDisp) {
              temp = cantTotalInicial.value - agrupadoStockDisp
            }
            medicamentosAgrupadosIngresar.value.map(a => {
              while (temp > 0 && a.cantidadAgg > 0 && a.CodMx === medicamentoCompletarAgrupados.value.ItemCode) {
                temp -= 1
                a.cantidadAgg -= 1
              }
              if (a.cantidadAgg > 0) {
                const object = {
                  BatchNumber: a.NumLote,
                  FecVcto: a.FecVcto,
                  Quantity: a.cantidadAgg,
                  cantstock: a.cant_stock_real ? a.cant_stock_real : a.cantstock,
                  ItemCode: a.CodMx,
                  WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
                  ObjectType: 15,
                  valor_anterior: a.cantidadAgg,
                  cantstock_unitario: a.cantstock
                }
                let mxIngreso = {}
                if (medicamentoCompletarAgrupados.value.ItemCode === object.ItemCode) {
                  mxIngreso = medicamentoCompletarAgrupados.value
                } else {
                  mxIngreso = {
                    ...medicamentoCompletarAgrupados.value,
                    Id: null,
                    id_ovdt: null,
                    ItemCode: a.CodMx,
                    ItemName: a.ItemName,
                    UseBaseUnits: a.UnitMedida[0].code,
                    UnitsOfMeasurment: a.UnitMedida[0].value,
                    UnitPrice: a.Price,
                    ArticuloId: a.ArticuloId || null,
                    precio_unitario: a.Price,
                    StockTotal: a.StockTotal,
                    StockDisponible: a.StockDisponible,
                    stock_disponible_unitario: a.StockDisponible,
                    stock_total_unitario: a.StockTotal,
                    Quantity: a.cantidadAgg,
                    FecVcto: a.FecVcto,
                    DelivrdQty: a.cantidadAgg,
                    OpenCreQty: 0,
                    unidades_medida: a.UnitMedida,
                    apartados: [],
                    name: a.name,
                    LineNum: medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1,
                    LineStatus: a.cantstock ? 1 : 0,
                    unidadSeleccionada: { ...a.UnitMedida[0] },
                    StockMovements: [object]
                  }
                }
                ingresarMedicamentoTabla(mxIngreso, object)
              }
            })
            Swal.fire(
              'Guardado',
              'Pendiente creado con éxito',
              'success'
            ).then(() => {
              cerrarModalAgrupados()
            })
          } else {
            getApartado(medicamentoCompletarAgrupados.value.ItemCode, ((cantTotalInicial.value > agrupadoStockTotal ? agrupadoStockTotal - agrupadoStockDisp : cantidad) + pendientes || 0))
            medicamentoTemporalIngresarApartado.value = medicamentoCompletarAgrupados.value
          }
          return false
        })
      } else {
        medicamentosAgrupadosIngresar.value.map(a => {
          const object = {
            BatchNumber: a.NumLote,
            FecVcto: a.FecVcto,
            Quantity: a.cantidadAgg,
            cantstock: a.cant_stock_real ? a.cant_stock_real : a.cantstock,
            ItemCode: a.CodMx,
            WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
            ObjectType: 15,
            valor_anterior: a.cantidadAgg,
            cantstock_unitario: a.cant_stock_real ? a.cant_stock_real : a.cantstock
          }
          const mxIngreso = medicamentoCompletarAgrupados.value.ItemCode === object.ItemCode ? medicamentoCompletarAgrupados.value : {
            ...medicamentoCompletarAgrupados.value,
              ItemCode: a.CodMx,
              id_ovdt: null,
              ItemName: a.ItemName,
              UseBaseUnits: a.UnitMedida[0].code,
              UnitsOfMeasurment: a.UnitMedida[0].value,
              UnitPrice: a.Price,
              ArticuloId: a.ArticuloId || null,
              precio_unitario: a.Price,
              StockTotal: a.StockTotal,
              StockDisponible: a.StockDisponible,
              stock_disponible_unitario: a.StockDisponible,
              stock_total_unitario: a.StockTotal,
              Quantity: a.cantidadAgg,
              FecVcto: a.FecVcto,
              DelivrdQty: a.cantidadAgg,
              OpenCreQty: 0,
              unidades_medida: a.UnitMedida,
              apartados: [],
              Id: null,
              name: a.name,
              LineNum: medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1,
              LineStatus: a.cantstock ? 1 : 0,
              unidadSeleccionada: { ...a.UnitMedida[0] },
              StockMovements: [object]
          }
          ingresarMedicamentoTabla(mxIngreso, object)
        })
        medicamentosStore.medicamentos_tabla.map(a => {
          if (a.ItemCode === medicamentoCompletarAgrupados.value.ItemCode) {
            if (pendientes > 0) {
              a.OpenCreQty = pendientes
            }
          }
          _sumarCantidades(a)
        })
        Swal.fire(
          'Guardado!',
          'Agrupados ingresados con éxito!',
          'success'
        ).then(() => {
          cerrarModalAgrupados()
        })
      }
    }
    const _sumarCantidades = (medicamento) => {
      medicamento.DelivrdQty = 0
      medicamento.StockMovements.map(a => {
        medicamento.DelivrdQty += a.Quantity
      })
      medicamento.Quantity = medicamento.DelivrdQty + medicamento.OpenCreQty
    }
    const cantidadFaltante = () => {
      if (medicamentosAgrupadosIngresar.value.length) {
        let cantidad = conteoMedicamentosPendientes.value
        medicamentosAgrupadosIngresar.value.map(a => {
          cantidad -= a.cantidadAgg
        })
        return cantidad <= 0 ? 0 : cantidad
      }
      return conteoMedicamentosPendientes.value
    }
    const cerrarModalAgrupados = () => {
      conteoMedicamentosPendientes.value = 0
      medicamentosAgrupadosIngresar.value = []
      medicamentosAgrupados.value = []
      displayModalMedicamentosAgrupados.value = false
    }
    const devolverCantidad = () => {
      medicamentosStore.medicamentos_tabla.map(a => {
        if (a.ItemCode === loteTemporalIngresarApartado.value?.ItemCode || a.ItemCode === loteTemporalIngresarAgrupado.value?.ItemCode) {
          if (a.StockMovements.length > 0) {
            for (const i of a.StockMovements) {
              if (i.BatchNumber === loteTemporalIngresarApartado.value.BatchNumber) {
                if (i.valor_anterior > a.StockDisponible) {
                  i.Quantity = 0
                } else {
                  i.Quantity = i.valor_anterior ? i.valor_anterior : 0
                }
              }
              if (i.BatchNumber === loteTemporalIngresarAgrupado.value.BatchNumber) {
                if (i.valor_anterior > a.StockDisponible) {
                  i.Quantity = 0
                } else {
                  i.Quantity = i.valor_anterior ? i.valor_anterior : 0
                }
              }
            }
          }
        }
      })
      loteTemporalIngresarAgrupado.value = {}
    }
    const eliminarMedicamentos = () => {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Esta seguro de eliminar este registro?',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if (lineasMdsSeleccionadas.value.length) {
            const array = []
            for (const i of medicamentosStore._medicamentos_tabla) {
              if (!lineasMdsSeleccionadas.value.some(e => e.ItemCode === i.ItemCode)) {
                array.push(i)
              }
            }
            medicamentosStore.medicamentos_tabla = array
            lineasMdsSeleccionadas.value = []
          }
          Swal.fire(
            'Guardado!',
            'Lineas eliminadas con éxito!',
            'success'
          )
          expandedRows.value = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode)
        }
      })
    }
    const eliminarLotes = (medicamento, lote) => {
      Swal.fire({
        title: 'Advertencia!',
        text: `Esta seguro de eliminar el lote ${lote.BatchNumber} del medicamento ${medicamento.ItemName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if (medicamento.apartados) {
            let cantidadRestar = lote.Quantity
            medicamento.apartados.map(a => {
              while (a.Cantidad > 0 && cantidadRestar > 0) {
                cantidadRestar -= 1
                a.Cantidad -= 1
              }
            })
            medicamento.apartados = medicamento.apartados.filter(a => a.Cantidad !== 0)
          }
          if (medicamento.StockMovements.length === 1) {
            Swal.fire({
              title: 'Advertencia!',
              text: `Al eliminar este lote, eliminaras el medicamento ${medicamento.ItemName} y sus pendientes, ¿estas seguro de realizar esta acción?`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar'
            }).then((result2) => {
              if (result2.isConfirmed) {
                medicamentosStore.medicamentos_tabla = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode !== medicamento.ItemCode)
                Swal.fire(
                  'Guardado!',
                  'Medicamento eliminado con éxito!',
                  'success'
                )
                medicamento.OpenCreQty = 0
                // medicamento.apartados = []
                _sumarCantidades(medicamento)
              }
            })
          } else {
            medicamentosStore._medicamentos_tabla.map(a => {
              a.StockMovements = a.StockMovements.filter(e => e.BatchNumber !== lote.BatchNumber)
            })
            medicamento.OpenCreQty = 0
            // medicamento.apartados = []
            _sumarCantidades(medicamento)
            Swal.fire(
              'Guardado!',
              'Lote eliminado con éxito!',
              'success'
            )
          }
        }
      })
    }
    const validarReemplazarMedicamentoAdvertencia = (medicamento) => {
      const porEliminar = medicamento.StockMovements ? medicamento.StockMovements : []
      if (medicamento.OpenCreQty) {
        porEliminar.push({
          Quantity: medicamento.OpenCreQty
        })
      }
      Swal.fire({
        title: 'Advertencia',
        icon: 'question',
        html: `
        <span>El artículo ${medicamento.ItemCode} - ${medicamento.ItemName} devolverá el stock entregado y eliminará pendientes.</span>
        <p>
          <table class="w-full m-4" style="border-collapse: collapse;border: 1px solid;">
            <thead style="border: 1px solid;">
              <tr>
                <td>Cantidad</td>
                <td>Estado</td>
              </tr>
            </thead>
            ${porEliminar.map(a => `<tr><td>${a.Quantity}</td><td>${a.BatchNumber ? 'Por entregar' : 'Pendientes' }</td></tr>`).join('')}
          </table>
        </p>
        <span>¿Esta seguro de reemplazar este artículo?</span>
        `,
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          medicamentoAReemplazar.value = medicamento
          displayModalReemplazarMd.value = true
        }
      })
    }
    const reemplazarMedicamento = (medicamento) => {
      Swal.fire(
        'Reemplazado',
        `Artículo reemplazado con exito por ${medicamentoAReemplazar.value.ItemCode}`,
        'success'
      ).then(() => {
        const object = { ...medicamentoAReemplazar.value }
        object.StockMovements = medicamento.cantstock ? [
          {
            BatchNumber: medicamento.NumLote,
            FecVcto: medicamento.FecVcto,
            Quantity: 0,
            cantstock: medicamento.cantstock,
            ItemCode: medicamento.CodMx,
            WhsCode: bodegasStore._bodega_seleccionada.WhsCode,
            ObjectType: 15,
            valor_anterior: 0,
            cantstock_unitario: medicamento.cantstock
          }
        ] : []
        object.ItemCode = medicamento.CodMx
        object.ItemName = medicamento.ItemName
        object.GrpCode = medicamento.GrpCode
        object.UseBaseUnits = medicamento.UnitMedida[0].code
        object.UnitsOfMeasurment = medicamento.UnitMedida[0].value
        object.UnitPrice = medicamento.Price ? parseInt(medicamento.Price) : null
        object.CostingCode = bodegaSeleccionada.value.U_PHR_CentroCosto
        object.LineNum = medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.length + 1 : 1
        object.LineStatus = medicamento.cantstock ? 1 : 0
        object.unidades_medida = medicamento.UnitMedida
        object.unidadSeleccionada = { ...medicamento.UnitMedida[0] }
        object.name = medicamento.name
        object.U_PHR_CtoAsociado = medicamento.U_PHR_CtoAsociado
        object.OnHandQty = medicamento.cantstock ? medicamento.cantstock : 0
        object.ArticuloId = medicamento.ArticuloId
        object.stock_total_unitario = medicamento.StockTotal
        object.StockTotal = medicamento.StockTotal
        object.StockDisponible = medicamento.StockDisponible
        object.stock_disponible_unitario = medicamento.StockDisponible
        object.precio_unitario = medicamento.Price ? parseInt(medicamento.Price) : null
        object.Quantity = 0
        object.OpenCreQty = 0
        object.DelivrdQty = 0
        const index = medicamentosStore._medicamentos_tabla.findIndex(a => a.ItemCode === medicamentoAReemplazar.value.ItemCode)
        medicamentosStore.medicamentos_tabla.splice(index, 0, object)
        medicamentosStore.medicamentos_tabla = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode !== medicamentoAReemplazar.value.ItemCode)
        cerrarDisplayReemplazarMedicamento()
      })
    }
    const cerrarDisplayReemplazarMedicamento = () => {
      displayModalReemplazarMd.value = false
      medicamentoSeleccionado.value = ''
      medicamentoAReemplazar.value = {}
    }
    const obtenerDetalleMedicamento = (medicamento) => {
      medicamentosStore.$patch((state) => {
        state.display_ver_medicamento = true
      })
      medicamentosStore.findByItemCode(medicamento.ItemCode)
    }
    const abrirModalPendiente = (medicamento) => {
      displayModalMedicamentoPendiente.value = true
      lotePendienteSeleccionado.value = { ...medicamento }
    }
    const actualizarPendiente = () => {
      medicamentosStore.medicamentos_tabla.map(a => {
        if (a.ItemCode === lotePendienteSeleccionado.value.ItemCode) {
          a.OpenCreQty = lotePendienteSeleccionado.value.Quantity - lotePendienteSeleccionado.value.DelivrdQty
          a.Quantity = lotePendienteSeleccionado.value.DelivrdQty + a.OpenCreQty
        }
      })
      cerrarModalPendiente()
      Swal.fire(
        'Guardado!',
        'Pendiente actualizado con éxito!',
        'success'
      )
    }
    const cerrarModalPendiente = () => {
      displayModalMedicamentoPendiente.value = false
      lotePendienteSeleccionado.value = ''
    }
    const buscarMedicos = (event) => {
      if (event.query.length > 2) {
        _medicosStore.obtenerListadoMedicos(event.query, { limit: 20 })
      }
    }
    const buscarIps = (event) => {
      if (event.query.length > 2) {
        _ipsStore.obtenerListadoIps(event.query, { limit: 20 })
      }
    }
    const eliminarPendiente = (medicamento) => {
      Swal.fire({
        title: 'Advertencia',
        icon: 'warning',
        text: `¿Esta seguro de eliminar los pendientes del medicamento ${medicamento.ItemName}?`,
        showCancelButton: true,
        confirmButtonText: 'Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          medicamentosStore._medicamentos_tabla.map(a => {
            if (a.ItemCode === medicamento.ItemCode) {
              a.OpenCreQty = 0
            }
          })
          displayModalMedicamentosPendientesListado.value = false
          Swal.fire(
            'Guardado',
            'Pendientes eliminados con éxito',
            'success'
          )
        }
      })
    }
    const validacionCampoRequerido = (campo, value) => {
      const valor = columnasTabla.value.find(a => a.ColName === campo)
      return !!(valor.IsRequired && !value && creandoOrden.value)
    }
    const validarColumnasTabla = (columna) => {
      if (columnasTabla.value.some(a => a.ColName === columna)) {
        return !columnasTabla.value.find(a => a.ColName === columna).campo_oculto
      } else {
        return false
      }
    }
    const rowClass = (data) => {
      return [{ 'row-temp-bg': data.ItemCode === rowData.value[0].ItemCode }]
    }
    const abrirModalListadoMedicamentosPendientes = () => {
      displayModalMedicamentosPendientesListado.value = true
      listadoPendientesTotales.value = []
      medicamentosStore._medicamentos_tabla.map(a => {
        if (a.OpenCreQty) {
          listadoPendientesTotales.value.push({ ...a })
        }
      })
    }
    const actualizarListadoMedicamentosPendientes = () => {
      displayModalMedicamentosPendientesListado.value = false
      Swal.fire(
        'Guardado!',
        'Pendientes actualizados con éxito!',
        'success'
      ).then(() => {
        listadoPendientesTotales.value.map(a => {
          const medicamento = medicamentosStore._medicamentos_tabla.find(e => e.ItemCode === a.ItemCode)
          medicamento.OpenCreQty = a.OpenCreQty
          _sumarCantidades(medicamento)
        })
      })
    }
    const cerrarModalListadoMedicamentosPendientes = () => {
      listadoPendientesTotales.value = []
      displayModalMedicamentosPendientesListado.value = false
    }
    const obtenerNumerosDireccionamiento = (event) => {
      const array = codigosMipres.value.filter(a => a.NoPrescripcion === event.value.NoPrescripcion)
      array.map(a => {
        a.name = a.iddireccionamiento + ' Fec. max ent-> ' + a.fecmaxent
      })
      numerosDireccionamiento.value = array
    }
    const toggle = (event) => {
      op.value.toggle(event)
    }
    const calcularValorCuota = () => {
      if (pacientesStore._paciente_seleccionado) {
        if (ordenesStore._udf.cuota_moderadora) {
          ordenesStore.mostrar_calcular_cuota = false
          ordenesStore.footer.total_cuota_moderadora = ordenesStore._footer.exonera_cuota === '02' ? ordenesStore._udf.cuota_moderadora : 0
        } else calcularCuotaRecuperacion()
      }
    }
    const calcularCuotaRecuperacion = () => {
      let temp = 0
      if (ordenesStore._udf.porcentaje_cuota > 0) {
        for (const i of medicamentosStore.medicamentos_tabla) {
          if (!i.U_PHR_Exonerado) {
            return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes completar todos los datos de la tabla', life: 5000 })
          } else {
            if (i.U_PHR_Exonerado === 'N') {
              temp += (parseInt(i.UnitPrice) * i.Quantity) * ordenesStore._udf.porcentaje_cuota / 100
            }
          }
        }
      }
      ordenesStore.footer.total_cuota_recuperacion = temp
      ordenesStore.mostrar_calcular_cuota = false
    }
    const validarCantidadMaximaPorInputApartados = (cantidadRestante, cantidadTotalCompletar) => {
      return cantidadTotalCompletar > cantidadRestante ? cantidadRestante : cantidadTotalCompletar
    }
    const validarOpcionesIdsDireccionamiento = (linea) => {
      const array = []
      for (const i of mipresStore.listado_numeros_direccionamiento) {
        if (medicamentosStore._medicamentos_tabla.some(j => j.U_PHR_NumDirec === i.iddireccionamiento)) {
          if (medicamentosStore._medicamentos_tabla.find(j => j.U_PHR_NumDirec === i.iddireccionamiento).ItemCode === linea.ItemCode) {
            array.push(i)
          }
        } else {
          array.push(i)
        }
      }
      return array
    }
    const imprimir = (ov) => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA}/ov/generar-pdf/${ov}`
      const token = JSON.parse(Cookies.get('user')).token
      const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
      window.open(res, '_blank')
    }
    const cambioNumeroDireccionamiento = (linea) => {
      const mipres = validarOpcionesIdsDireccionamiento(linea).find(a => a.iddireccionamiento === linea.U_PHR_NumDirec)
      const nombreSeguimiento = `${mipres.noentrega}/${mipres.totalEntregar}`
      linea.U_PHR_SeguiEntrega = ordenesStore._listado_seguimientos_entrega.find(a => a.Name === nombreSeguimiento.toString()).Code
      linea.U_PHR_NumEntregas = mipres.noentrega
    }
    const cambiarUnidadMedidaInventario = (medicamento) => {
      medicamento.OpenCreQty = 0
      medicamento.UnitsOfMeasurment = medicamento.unidadSeleccionada.value
      medicamento.UseBaseUnits = medicamento.unidadSeleccionada.code
      if (medicamento.unidadSeleccionada.value === 1) {
        medicamento.StockTotal = medicamento.stock_total_unitario
        medicamento.StockDisponible = medicamento.stock_disponible_unitario
        medicamento.UnitPrice = medicamento.precio_unitario
        if (medicamento.StockMovements.length) {
          medicamento.StockMovements.map(e => {
            e.Quantity = e.Quantity * medicamento.unidadSeleccionada.value
            e.cantstock = e.cantstock_unitario
          })
        }
      } else {
        medicamento.StockTotal = medicamento.stock_total_unitario * medicamento.unidadSeleccionada.value
        medicamento.StockDisponible = medicamento.stock_disponible_unitario * medicamento.unidadSeleccionada.value
        medicamento.UnitPrice = medicamento.precio_unitario * medicamento.unidadSeleccionada.value
        if (medicamento.StockMovements.length) {
          medicamento.StockMovements.map(e => {
            e.cantstock = Math.trunc(e.cantstock_unitario / medicamento.unidadSeleccionada.value)
            e.Quantity = Math.trunc(e.Quantity / medicamento.unidadSeleccionada.value)
          })
        }
      }
      _sumarCantidades(medicamento)
    }
    const crearOv = () => {
      if (!medicamentosStore._medicamentos_tabla.length) return Swal.fire('Advertencia!', 'La orden no se puede crear vacia, por favor ingresa un artículo para iniciar', 'error')
      Swal.fire({
        title: '¿Esta seguro?',
        icon: 'warning',
        text: '¿Desea confirmar la creación de la Orden de Venta?',
        showCancelButton: true,
        confirmButtonText: 'Si, crear',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          ordenesStore.creando_orden = true
          if (medicamentosStore._medicamentos_tabla.some(a => +a.Quantity <= 0)) {
            medicamentosStore._medicamentos_tabla.map(e => {
              if (!e.OnHandQty) {
                toast.add({ severity: 'error', summary: 'Error', detail: `El artículo ${e.ItemCode + ' - ' + e.ItemName} debe tener un pendiente`, life: 3000 })
              } else {
                toast.add({ severity: 'error', summary: 'Error', detail: `La cantidad del artículo ${e.ItemCode + ' - ' + e.ItemName} no puede ser 0`, life: 3000 })
              }
            })
          } else {
            ordenesStore.crearOrdenVenta().then(async (data) => {
              if (data.data.erroresTopes) {
                return Swal.fire({
                  icon: 'error',
                  title: `Por nota técnica de ${ordenesStore._header.cliente.CardName}`,
                  width: '52rem',
                  html: `
                  <div>
                    <p>La siguiente agrupación supera el límite de cantidad a dispensar</p>
                    <br/>
                    <div class="w-full overflow-x-hidden overflow-y-auto">
                      <table style="width: 44rem" class="m-4 text-sm border" style="border-collapse: collapse; border: 1px solid;">
                        <thead class="font-bold" style="border: 1px solid;">
                          <tr>
                            <td class="border">Medicamento</td>
                            <td class="border">Agrupación</td>
                            <td class="border">Ct. Digitada</td>
                            <td class="border">Ct. Existente</td>
                            <td class="border">Ct. Tope total</td>
                            <td class="border">Tope</td>
                          </tr>
                        </thead>
                        ${data.data.medicamentos.map(a => `
                          <tr class="text-left" style="border: 1px solid">
                            <td class="p-1 border text-center">${a.md}</td>
                            <td class="p-1 border text-center">${a.agrupacion}</td>
                            <td class="p-1 border text-center">${a.cantidadDigitada.toLocaleString('es-CO')}</td>
                            <td class="p-1 border text-center">${a.cantidadExistente.toLocaleString('es-CO')}</td>
                            <td class="p-1 border text-center">${a.cantidadTotal.toLocaleString('es-CO')}</td>
                            <td class="p-1 border text-center">${a.tope.toLocaleString('es-CO')}</td>
                          </tr>
                          `).join('')}
                      </table>
                    </div>
                  </div>
                  `
                })
              } else if (data.data.length) {
                ordenesCreadas.value = data.data
                desplayModalCrearOv.value = true
              } else if (data.erroresPgp.length) {
                return Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  width: '52rem',
                  html: `
                  <div>
                    <p>Error al crear la orden en pago prospectivo</p>
                    <br/>
                    <div class="max-h-56 w-full overflow-x-hidden overflow-y-auto">
                      <table class="w-auto m-4 text-sm border" style="border-collapse: collapse; border: 1px solid;">
                        ${data.erroresPgp.map(a => `<tr class="text-left" style="border-bottom: 1px solid"><td class="p-1">${a.Message}</td></tr>`).join('')}
                      </table>
                    </div>
                  </div>
                  `
                })
              } else {
                console.log('no valido nada')
              }
            })
          }
        }
      })
    }
    // Activación buscador de medicamentos
    window.addEventListener(
      'keypress',
      (event) => {
        const keyName = event.key
        if (keyName === '\x19') {
          document.getElementById('inputElement').click()
        }
      },
      false
    )
    onMounted(() => {
      ordenesStore.listarSeguimientoEntregas()
      ordenesStore.listarDuracionesTratamiento()
      ordenesStore.listarFrecuencias()
    })
    return {
      show,
      rowData,
      buscarMedicamentos,
      medicamentoSeleccionado,
      displayModalReemplazarMd,
      dayjs,
      expandedRows,
      columnasTabla,
      arrSiNo,
      lineasMdsSeleccionadas,
      eliminarMedicamentos,
      listadoMedicos,
      buscarMedicos,
      buscarIps,
      listadoIps,
      codigosMipres,
      numerosDireccionamiento,
      persona,
      crearOv,
      calcularValorCuota,
      opcionesExonera,
      creandoOrden,
      validacionCampoRequerido,
      bodegaSeleccionada,
      obtenerDetalleMedicamento,
      displayModalMedicamentosAgrupados,
      medicamentosAgrupados,
      medicamentosAgrupadosIngresar,
      conteoMedicamentosPendientes,
      displayModalMedicamentoPendiente,
      displayModalMedicamentosApartados,
      lotePendienteSeleccionado,
      abrirModalPendiente,
      cerrarModalPendiente,
      actualizarPendiente,
      displayModalMedicamentosPendientesListado,
      abrirModalListadoMedicamentosPendientes,
      listadoPendientesTotales,
      cerrarModalListadoMedicamentosPendientes,
      actualizarListadoMedicamentosPendientes,
      eliminarPendiente,
      rowClass,
      cerrarDisplayReemplazarMedicamento,
      obtenerNumerosDireccionamiento,
      medicamentoAReemplazar,
      reemplazarMedicamento,
      validarColumnasTabla,
      calcularCuotaRecuperacion,
      mostrarCalcularCuota,
      validarReemplazarMedicamentoAdvertencia,
      modalEditUnidades,
      unidadEditar,
      baseUrl,
      mdApartados,
      apartadosSend,
      seleccionarApartados,
      op,
      toggle,
      validarIngresoMedicamento,
      ordenesStore,
      validarCantidadesIngresadas,
      cantidadFaltante,
      medicamentoCompletarAgrupados,
      medicamentosStore,
      ingresarMedicamentosAgrupados,
      cerrarModalAgrupados,
      lineasLotesSeleccionados,
      eliminarLotes,
      pacientesStore,
      getApartado,
      lodash,
      cerrarModalApartados,
      validarCantidadMaximaPorInputApartados,
      mipresStore,
      validarOpcionesIdsDireccionamiento,
      desplayModalCrearOv,
      ordenesCreadas,
      imprimir,
      cambioNumeroDireccionamiento,
      cambiarUnidadMedidaInventario,
      motivosAutorizacionStore,
      devolverCantidad,
      medicamentoTemporalIngresarApartado,
      cantTotalInicial
    }
  }
}
</script>

<style>
  ::-webkit-scrollbar {
    height: 0.3rem;
    width: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  .row-temp-bg {
    animation-duration: 3s;
    animation-name: slidein;
    animation-play-state: running;
  }
  .h-panel {
    max-height: 300px!important;
  }

  @keyframes slidein {
    from {
      background: rgb(91, 195, 255);
    }

    to {
      background: white;
    }
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
